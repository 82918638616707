@charset "UTF-8";
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("/content/commun/fonts/material-icons.woff2?v=2") format("woff2"), url("/content/commun/fonts/material-icons.woff") format("woff"), url("/content/commun/fonts/material-icons.ttf") format("truetype");
}
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url("/content/commun/fonts/material-icons-outlined.woff2") format("woff2"), url("/content/commun/fonts/material-icons-outlined.woff") format("woff"), url("/content/commun/fonts/material-icons-outlined.ttf") format("truetype");
}
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url("/content/commun/fonts/material-icons-round.woff2") format("woff2"), url("/content/commun/fonts/material-icons-round.woff") format("woff"), url("/content/commun/fonts/material-icons-round.ttf") format("truetype");
}
@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  src: url("/content/commun/fonts/material-icons-sharp.woff2") format("woff2"), url("/content/commun/fonts/material-icons-sharp.woff") format("woff"), url("/content/commun/fonts/material-icons-sharp.ttf") format("truetype");
}
@font-face {
  font-family: "Material Icons TwoTone";
  font-style: normal;
  font-weight: 400;
  src: url("/content/commun/fonts/material-icons-two-tone.woff2") format("woff2"), url("/content/commun/fonts/material-icons-two-tone.woff") format("woff"), url("/content/commun/fonts/material-icons-two-tone.ttf") format("truetype");
}
[class*=material-icons] {
  display: inline-block;
  font-family: "Material Icons";
  font-size: 21px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  -webkit-font-feature-settings: "liga";
  font-feature-settings: "liga";
}

.material-icons {
  font-family: "Material Icons";
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
}

.material-icons-round {
  font-family: "Material Icons Round";
}

.material-icons-sharp {
  font-family: "Material Icons Sharp";
}

.material-icons-twotone {
  font-family: "Material Icons TwoTone";
}

/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
/*********************************** Breakpoints ************************************************/
/*********************************** Colors *****************************************************/
/*********************************** typography *****************************************************/
/*********************************** spacing *****************************************************/
/*********************************** Colors *****************************************************/
/*********************************** Colors *****************************************************/
.ia-spacer-full-width {
  position: relative;
}
.padding-16-36 .ia-spacer-full-width {
  margin-left: -1.1428571429rem;
  margin-right: -1.1428571429rem;
}
.padding-32-36 .ia-spacer-full-width, .padding-32-24 .ia-spacer-full-width¸, .padding-32-48 .ia-spacer-full-width, .padding-32-60 .ia-spacer-full-width {
  margin-left: -1.1428571429rem;
  margin-right: -1.1428571429rem;
}
@media screen and (min-width: 768px) {
  .padding-32-36 .ia-spacer-full-width, .padding-32-24 .ia-spacer-full-width¸, .padding-32-48 .ia-spacer-full-width, .padding-32-60 .ia-spacer-full-width {
    margin-left: -2.2857142857rem;
    margin-right: -2.2857142857rem;
  }
}

.margin-0 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.padding-16-24 {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 1.7142857143rem;
  padding-bottom: 1.7142857143rem;
}

.padding-16-36 {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 2.5714285714rem;
  padding-bottom: 2.5714285714rem;
}

.padding-24-36 {
  padding-left: 1.7142857143rem;
  padding-right: 1.7142857143rem;
  padding-top: 2.5714285714rem;
  padding-bottom: 2.5714285714rem;
}

.padding-32-36 {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 2.5714285714rem;
  padding-bottom: 2.5714285714rem;
}
@media screen and (min-width: 768px) {
  .padding-32-36 {
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}

.padding-32-24 {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 1.7142857143rem;
  padding-bottom: 1.7142857143rem;
}
@media screen and (min-width: 768px) {
  .padding-32-24 {
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}

.padding-32-48 {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 3.4285714286rem;
  padding-bottom: 3.4285714286rem;
}
@media screen and (min-width: 768px) {
  .padding-32-48 {
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}

.padding-32-60 {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 4.2857142857rem;
  padding-bottom: 4.2857142857rem;
}
@media screen and (min-width: 768px) {
  .padding-32-60 {
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}

.margin-top-auto {
  margin-top: auto !important;
}

.margin-top-auto-md {
  margin-top: auto !important;
}

.margin-top-auto-lg {
  margin-top: auto !important;
}

.margin-left-auto {
  margin-left: auto !important;
}

.margin-left-auto-md {
  margin-left: auto !important;
}

.margin-left-auto-lg {
  margin-left: auto !important;
}

.margin-bottom-auto {
  margin-bottom: auto !important;
}

.margin-bottom-auto-md {
  margin-bottom: auto !important;
}

.margin-bottom-auto-lg {
  margin-bottom: auto !important;
}

.margin-right-auto {
  margin-right: auto !important;
}

.margin-right-auto-md {
  margin-right: auto !important;
}

.margin-right-auto-lg {
  margin-right: auto !important;
}

.margin-top-none {
  margin-top: 0 !important;
}

.margin-top-none-md {
  margin-top: 0 !important;
}

.margin-top-none-lg {
  margin-top: 0 !important;
}

.margin-left-none {
  margin-left: 0 !important;
}

.margin-left-none-md {
  margin-left: 0 !important;
}

.margin-left-none-lg {
  margin-left: 0 !important;
}

.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-bottom-none-md {
  margin-bottom: 0 !important;
}

.margin-bottom-none-lg {
  margin-bottom: 0 !important;
}

.margin-right-none {
  margin-right: 0 !important;
}

.margin-right-none-md {
  margin-right: 0 !important;
}

.margin-right-none-lg {
  margin-right: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-0-md {
  margin-top: 0 !important;
}

.margin-top-0-lg {
  margin-top: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-left-0-md {
  margin-left: 0 !important;
}

.margin-left-0-lg {
  margin-left: 0 !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-0-md {
  margin-bottom: 0 !important;
}

.margin-bottom-0-lg {
  margin-bottom: 0 !important;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-0-md {
  margin-right: 0 !important;
}

.margin-right-0-lg {
  margin-right: 0 !important;
}

.margin-top-xxs {
  margin-top: 0.2857142857rem !important;
}

.margin-top-xxs-md {
  margin-top: 0.2857142857rem !important;
}

.margin-top-xxs-lg {
  margin-top: 0.2857142857rem !important;
}

.margin-left-xxs {
  margin-left: 0.2857142857rem !important;
}

.margin-left-xxs-md {
  margin-left: 0.2857142857rem !important;
}

.margin-left-xxs-lg {
  margin-left: 0.2857142857rem !important;
}

.margin-bottom-xxs {
  margin-bottom: 0.2857142857rem !important;
}

.margin-bottom-xxs-md {
  margin-bottom: 0.2857142857rem !important;
}

.margin-bottom-xxs-lg {
  margin-bottom: 0.2857142857rem !important;
}

.margin-right-xxs {
  margin-right: 0.2857142857rem !important;
}

.margin-right-xxs-md {
  margin-right: 0.2857142857rem !important;
}

.margin-right-xxs-lg {
  margin-right: 0.2857142857rem !important;
}

.margin-top-4 {
  margin-top: 0.2857142857rem !important;
}

.margin-top-4-md {
  margin-top: 0.2857142857rem !important;
}

.margin-top-4-lg {
  margin-top: 0.2857142857rem !important;
}

.margin-left-4 {
  margin-left: 0.2857142857rem !important;
}

.margin-left-4-md {
  margin-left: 0.2857142857rem !important;
}

.margin-left-4-lg {
  margin-left: 0.2857142857rem !important;
}

.margin-bottom-4 {
  margin-bottom: 0.2857142857rem !important;
}

.margin-bottom-4-md {
  margin-bottom: 0.2857142857rem !important;
}

.margin-bottom-4-lg {
  margin-bottom: 0.2857142857rem !important;
}

.margin-right-4 {
  margin-right: 0.2857142857rem !important;
}

.margin-right-4-md {
  margin-right: 0.2857142857rem !important;
}

.margin-right-4-lg {
  margin-right: 0.2857142857rem !important;
}

.margin-top-xs {
  margin-top: 0.5714285714rem !important;
}

.margin-top-xs-md {
  margin-top: 0.5714285714rem !important;
}

.margin-top-xs-lg {
  margin-top: 0.5714285714rem !important;
}

.margin-left-xs {
  margin-left: 0.5714285714rem !important;
}

.margin-left-xs-md {
  margin-left: 0.5714285714rem !important;
}

.margin-left-xs-lg {
  margin-left: 0.5714285714rem !important;
}

.margin-bottom-xs {
  margin-bottom: 0.5714285714rem !important;
}

.margin-bottom-xs-md {
  margin-bottom: 0.5714285714rem !important;
}

.margin-bottom-xs-lg {
  margin-bottom: 0.5714285714rem !important;
}

.margin-right-xs {
  margin-right: 0.5714285714rem !important;
}

.margin-right-xs-md {
  margin-right: 0.5714285714rem !important;
}

.margin-right-xs-lg {
  margin-right: 0.5714285714rem !important;
}

.margin-top-8 {
  margin-top: 0.5714285714rem !important;
}

.margin-top-8-md {
  margin-top: 0.5714285714rem !important;
}

.margin-top-8-lg {
  margin-top: 0.5714285714rem !important;
}

.margin-left-8 {
  margin-left: 0.5714285714rem !important;
}

.margin-left-8-md {
  margin-left: 0.5714285714rem !important;
}

.margin-left-8-lg {
  margin-left: 0.5714285714rem !important;
}

.margin-bottom-8 {
  margin-bottom: 0.5714285714rem !important;
}

.margin-bottom-8-md {
  margin-bottom: 0.5714285714rem !important;
}

.margin-bottom-8-lg {
  margin-bottom: 0.5714285714rem !important;
}

.margin-right-8 {
  margin-right: 0.5714285714rem !important;
}

.margin-right-8-md {
  margin-right: 0.5714285714rem !important;
}

.margin-right-8-lg {
  margin-right: 0.5714285714rem !important;
}

.margin-top-s {
  margin-top: 0.8571428571rem !important;
}

.margin-top-s-md {
  margin-top: 0.8571428571rem !important;
}

.margin-top-s-lg {
  margin-top: 0.8571428571rem !important;
}

.margin-left-s {
  margin-left: 0.8571428571rem !important;
}

.margin-left-s-md {
  margin-left: 0.8571428571rem !important;
}

.margin-left-s-lg {
  margin-left: 0.8571428571rem !important;
}

.margin-bottom-s {
  margin-bottom: 0.8571428571rem !important;
}

.margin-bottom-s-md {
  margin-bottom: 0.8571428571rem !important;
}

.margin-bottom-s-lg {
  margin-bottom: 0.8571428571rem !important;
}

.margin-right-s {
  margin-right: 0.8571428571rem !important;
}

.margin-right-s-md {
  margin-right: 0.8571428571rem !important;
}

.margin-right-s-lg {
  margin-right: 0.8571428571rem !important;
}

.margin-top-12 {
  margin-top: 0.8571428571rem !important;
}

.margin-top-12-md {
  margin-top: 0.8571428571rem !important;
}

.margin-top-12-lg {
  margin-top: 0.8571428571rem !important;
}

.margin-left-12 {
  margin-left: 0.8571428571rem !important;
}

.margin-left-12-md {
  margin-left: 0.8571428571rem !important;
}

.margin-left-12-lg {
  margin-left: 0.8571428571rem !important;
}

.margin-bottom-12 {
  margin-bottom: 0.8571428571rem !important;
}

.margin-bottom-12-md {
  margin-bottom: 0.8571428571rem !important;
}

.margin-bottom-12-lg {
  margin-bottom: 0.8571428571rem !important;
}

.margin-right-12 {
  margin-right: 0.8571428571rem !important;
}

.margin-right-12-md {
  margin-right: 0.8571428571rem !important;
}

.margin-right-12-lg {
  margin-right: 0.8571428571rem !important;
}

.margin-top-sm {
  margin-top: 1rem !important;
}

.margin-top-sm-md {
  margin-top: 1rem !important;
}

.margin-top-sm-lg {
  margin-top: 1rem !important;
}

.margin-left-sm {
  margin-left: 1rem !important;
}

.margin-left-sm-md {
  margin-left: 1rem !important;
}

.margin-left-sm-lg {
  margin-left: 1rem !important;
}

.margin-bottom-sm {
  margin-bottom: 1rem !important;
}

.margin-bottom-sm-md {
  margin-bottom: 1rem !important;
}

.margin-bottom-sm-lg {
  margin-bottom: 1rem !important;
}

.margin-right-sm {
  margin-right: 1rem !important;
}

.margin-right-sm-md {
  margin-right: 1rem !important;
}

.margin-right-sm-lg {
  margin-right: 1rem !important;
}

.margin-top-14 {
  margin-top: 1rem !important;
}

.margin-top-14-md {
  margin-top: 1rem !important;
}

.margin-top-14-lg {
  margin-top: 1rem !important;
}

.margin-left-14 {
  margin-left: 1rem !important;
}

.margin-left-14-md {
  margin-left: 1rem !important;
}

.margin-left-14-lg {
  margin-left: 1rem !important;
}

.margin-bottom-14 {
  margin-bottom: 1rem !important;
}

.margin-bottom-14-md {
  margin-bottom: 1rem !important;
}

.margin-bottom-14-lg {
  margin-bottom: 1rem !important;
}

.margin-right-14 {
  margin-right: 1rem !important;
}

.margin-right-14-md {
  margin-right: 1rem !important;
}

.margin-right-14-lg {
  margin-right: 1rem !important;
}

.margin-top-m {
  margin-top: 1.1428571429rem !important;
}

.margin-top-m-md {
  margin-top: 1.1428571429rem !important;
}

.margin-top-m-lg {
  margin-top: 1.1428571429rem !important;
}

.margin-left-m {
  margin-left: 1.1428571429rem !important;
}

.margin-left-m-md {
  margin-left: 1.1428571429rem !important;
}

.margin-left-m-lg {
  margin-left: 1.1428571429rem !important;
}

.margin-bottom-m {
  margin-bottom: 1.1428571429rem !important;
}

.margin-bottom-m-md {
  margin-bottom: 1.1428571429rem !important;
}

.margin-bottom-m-lg {
  margin-bottom: 1.1428571429rem !important;
}

.margin-right-m {
  margin-right: 1.1428571429rem !important;
}

.margin-right-m-md {
  margin-right: 1.1428571429rem !important;
}

.margin-right-m-lg {
  margin-right: 1.1428571429rem !important;
}

.margin-top-16 {
  margin-top: 1.1428571429rem !important;
}

.margin-top-16-md {
  margin-top: 1.1428571429rem !important;
}

.margin-top-16-lg {
  margin-top: 1.1428571429rem !important;
}

.margin-left-16 {
  margin-left: 1.1428571429rem !important;
}

.margin-left-16-md {
  margin-left: 1.1428571429rem !important;
}

.margin-left-16-lg {
  margin-left: 1.1428571429rem !important;
}

.margin-bottom-16 {
  margin-bottom: 1.1428571429rem !important;
}

.margin-bottom-16-md {
  margin-bottom: 1.1428571429rem !important;
}

.margin-bottom-16-lg {
  margin-bottom: 1.1428571429rem !important;
}

.margin-right-16 {
  margin-right: 1.1428571429rem !important;
}

.margin-right-16-md {
  margin-right: 1.1428571429rem !important;
}

.margin-right-16-lg {
  margin-right: 1.1428571429rem !important;
}

.margin-top-l {
  margin-top: 1.4285714286rem !important;
}

.margin-top-l-md {
  margin-top: 1.4285714286rem !important;
}

.margin-top-l-lg {
  margin-top: 1.4285714286rem !important;
}

.margin-left-l {
  margin-left: 1.4285714286rem !important;
}

.margin-left-l-md {
  margin-left: 1.4285714286rem !important;
}

.margin-left-l-lg {
  margin-left: 1.4285714286rem !important;
}

.margin-bottom-l {
  margin-bottom: 1.4285714286rem !important;
}

.margin-bottom-l-md {
  margin-bottom: 1.4285714286rem !important;
}

.margin-bottom-l-lg {
  margin-bottom: 1.4285714286rem !important;
}

.margin-right-l {
  margin-right: 1.4285714286rem !important;
}

.margin-right-l-md {
  margin-right: 1.4285714286rem !important;
}

.margin-right-l-lg {
  margin-right: 1.4285714286rem !important;
}

.margin-top-20 {
  margin-top: 1.4285714286rem !important;
}

.margin-top-20-md {
  margin-top: 1.4285714286rem !important;
}

.margin-top-20-lg {
  margin-top: 1.4285714286rem !important;
}

.margin-left-20 {
  margin-left: 1.4285714286rem !important;
}

.margin-left-20-md {
  margin-left: 1.4285714286rem !important;
}

.margin-left-20-lg {
  margin-left: 1.4285714286rem !important;
}

.margin-bottom-20 {
  margin-bottom: 1.4285714286rem !important;
}

.margin-bottom-20-md {
  margin-bottom: 1.4285714286rem !important;
}

.margin-bottom-20-lg {
  margin-bottom: 1.4285714286rem !important;
}

.margin-right-20 {
  margin-right: 1.4285714286rem !important;
}

.margin-right-20-md {
  margin-right: 1.4285714286rem !important;
}

.margin-right-20-lg {
  margin-right: 1.4285714286rem !important;
}

.margin-top-xl {
  margin-top: 1.7142857143rem !important;
}

.margin-top-xl-md {
  margin-top: 1.7142857143rem !important;
}

.margin-top-xl-lg {
  margin-top: 1.7142857143rem !important;
}

.margin-left-xl {
  margin-left: 1.7142857143rem !important;
}

.margin-left-xl-md {
  margin-left: 1.7142857143rem !important;
}

.margin-left-xl-lg {
  margin-left: 1.7142857143rem !important;
}

.margin-bottom-xl {
  margin-bottom: 1.7142857143rem !important;
}

.margin-bottom-xl-md {
  margin-bottom: 1.7142857143rem !important;
}

.margin-bottom-xl-lg {
  margin-bottom: 1.7142857143rem !important;
}

.margin-right-xl {
  margin-right: 1.7142857143rem !important;
}

.margin-right-xl-md {
  margin-right: 1.7142857143rem !important;
}

.margin-right-xl-lg {
  margin-right: 1.7142857143rem !important;
}

.margin-top-24 {
  margin-top: 1.7142857143rem !important;
}

.margin-top-24-md {
  margin-top: 1.7142857143rem !important;
}

.margin-top-24-lg {
  margin-top: 1.7142857143rem !important;
}

.margin-left-24 {
  margin-left: 1.7142857143rem !important;
}

.margin-left-24-md {
  margin-left: 1.7142857143rem !important;
}

.margin-left-24-lg {
  margin-left: 1.7142857143rem !important;
}

.margin-bottom-24 {
  margin-bottom: 1.7142857143rem !important;
}

.margin-bottom-24-md {
  margin-bottom: 1.7142857143rem !important;
}

.margin-bottom-24-lg {
  margin-bottom: 1.7142857143rem !important;
}

.margin-right-24 {
  margin-right: 1.7142857143rem !important;
}

.margin-right-24-md {
  margin-right: 1.7142857143rem !important;
}

.margin-right-24-lg {
  margin-right: 1.7142857143rem !important;
}

.margin-top-xxl {
  margin-top: 2rem !important;
}

.margin-top-xxl-md {
  margin-top: 2rem !important;
}

.margin-top-xxl-lg {
  margin-top: 2rem !important;
}

.margin-left-xxl {
  margin-left: 2rem !important;
}

.margin-left-xxl-md {
  margin-left: 2rem !important;
}

.margin-left-xxl-lg {
  margin-left: 2rem !important;
}

.margin-bottom-xxl {
  margin-bottom: 2rem !important;
}

.margin-bottom-xxl-md {
  margin-bottom: 2rem !important;
}

.margin-bottom-xxl-lg {
  margin-bottom: 2rem !important;
}

.margin-right-xxl {
  margin-right: 2rem !important;
}

.margin-right-xxl-md {
  margin-right: 2rem !important;
}

.margin-right-xxl-lg {
  margin-right: 2rem !important;
}

.margin-top-28 {
  margin-top: 2rem !important;
}

.margin-top-28-md {
  margin-top: 2rem !important;
}

.margin-top-28-lg {
  margin-top: 2rem !important;
}

.margin-left-28 {
  margin-left: 2rem !important;
}

.margin-left-28-md {
  margin-left: 2rem !important;
}

.margin-left-28-lg {
  margin-left: 2rem !important;
}

.margin-bottom-28 {
  margin-bottom: 2rem !important;
}

.margin-bottom-28-md {
  margin-bottom: 2rem !important;
}

.margin-bottom-28-lg {
  margin-bottom: 2rem !important;
}

.margin-right-28 {
  margin-right: 2rem !important;
}

.margin-right-28-md {
  margin-right: 2rem !important;
}

.margin-right-28-lg {
  margin-right: 2rem !important;
}

.margin-top-xxl-1 {
  margin-top: 2.2857142857rem !important;
}

.margin-top-xxl-1-md {
  margin-top: 2.2857142857rem !important;
}

.margin-top-xxl-1-lg {
  margin-top: 2.2857142857rem !important;
}

.margin-left-xxl-1 {
  margin-left: 2.2857142857rem !important;
}

.margin-left-xxl-1-md {
  margin-left: 2.2857142857rem !important;
}

.margin-left-xxl-1-lg {
  margin-left: 2.2857142857rem !important;
}

.margin-bottom-xxl-1 {
  margin-bottom: 2.2857142857rem !important;
}

.margin-bottom-xxl-1-md {
  margin-bottom: 2.2857142857rem !important;
}

.margin-bottom-xxl-1-lg {
  margin-bottom: 2.2857142857rem !important;
}

.margin-right-xxl-1 {
  margin-right: 2.2857142857rem !important;
}

.margin-right-xxl-1-md {
  margin-right: 2.2857142857rem !important;
}

.margin-right-xxl-1-lg {
  margin-right: 2.2857142857rem !important;
}

.margin-top-32 {
  margin-top: 2.2857142857rem !important;
}

.margin-top-32-md {
  margin-top: 2.2857142857rem !important;
}

.margin-top-32-lg {
  margin-top: 2.2857142857rem !important;
}

.margin-left-32 {
  margin-left: 2.2857142857rem !important;
}

.margin-left-32-md {
  margin-left: 2.2857142857rem !important;
}

.margin-left-32-lg {
  margin-left: 2.2857142857rem !important;
}

.margin-bottom-32 {
  margin-bottom: 2.2857142857rem !important;
}

.margin-bottom-32-md {
  margin-bottom: 2.2857142857rem !important;
}

.margin-bottom-32-lg {
  margin-bottom: 2.2857142857rem !important;
}

.margin-right-32 {
  margin-right: 2.2857142857rem !important;
}

.margin-right-32-md {
  margin-right: 2.2857142857rem !important;
}

.margin-right-32-lg {
  margin-right: 2.2857142857rem !important;
}

.margin-top-xxl-2 {
  margin-top: 2.5714285714rem !important;
}

.margin-top-xxl-2-md {
  margin-top: 2.5714285714rem !important;
}

.margin-top-xxl-2-lg {
  margin-top: 2.5714285714rem !important;
}

.margin-left-xxl-2 {
  margin-left: 2.5714285714rem !important;
}

.margin-left-xxl-2-md {
  margin-left: 2.5714285714rem !important;
}

.margin-left-xxl-2-lg {
  margin-left: 2.5714285714rem !important;
}

.margin-bottom-xxl-2 {
  margin-bottom: 2.5714285714rem !important;
}

.margin-bottom-xxl-2-md {
  margin-bottom: 2.5714285714rem !important;
}

.margin-bottom-xxl-2-lg {
  margin-bottom: 2.5714285714rem !important;
}

.margin-right-xxl-2 {
  margin-right: 2.5714285714rem !important;
}

.margin-right-xxl-2-md {
  margin-right: 2.5714285714rem !important;
}

.margin-right-xxl-2-lg {
  margin-right: 2.5714285714rem !important;
}

.margin-top-36 {
  margin-top: 2.5714285714rem !important;
}

.margin-top-36-md {
  margin-top: 2.5714285714rem !important;
}

.margin-top-36-lg {
  margin-top: 2.5714285714rem !important;
}

.margin-left-36 {
  margin-left: 2.5714285714rem !important;
}

.margin-left-36-md {
  margin-left: 2.5714285714rem !important;
}

.margin-left-36-lg {
  margin-left: 2.5714285714rem !important;
}

.margin-bottom-36 {
  margin-bottom: 2.5714285714rem !important;
}

.margin-bottom-36-md {
  margin-bottom: 2.5714285714rem !important;
}

.margin-bottom-36-lg {
  margin-bottom: 2.5714285714rem !important;
}

.margin-right-36 {
  margin-right: 2.5714285714rem !important;
}

.margin-right-36-md {
  margin-right: 2.5714285714rem !important;
}

.margin-right-36-lg {
  margin-right: 2.5714285714rem !important;
}

.margin-top-xxl-3 {
  margin-top: 3.4285714286rem !important;
}

.margin-top-xxl-3-md {
  margin-top: 3.4285714286rem !important;
}

.margin-top-xxl-3-lg {
  margin-top: 3.4285714286rem !important;
}

.margin-left-xxl-3 {
  margin-left: 3.4285714286rem !important;
}

.margin-left-xxl-3-md {
  margin-left: 3.4285714286rem !important;
}

.margin-left-xxl-3-lg {
  margin-left: 3.4285714286rem !important;
}

.margin-bottom-xxl-3 {
  margin-bottom: 3.4285714286rem !important;
}

.margin-bottom-xxl-3-md {
  margin-bottom: 3.4285714286rem !important;
}

.margin-bottom-xxl-3-lg {
  margin-bottom: 3.4285714286rem !important;
}

.margin-right-xxl-3 {
  margin-right: 3.4285714286rem !important;
}

.margin-right-xxl-3-md {
  margin-right: 3.4285714286rem !important;
}

.margin-right-xxl-3-lg {
  margin-right: 3.4285714286rem !important;
}

.margin-top-48 {
  margin-top: 3.4285714286rem !important;
}

.margin-top-48-md {
  margin-top: 3.4285714286rem !important;
}

.margin-top-48-lg {
  margin-top: 3.4285714286rem !important;
}

.margin-left-48 {
  margin-left: 3.4285714286rem !important;
}

.margin-left-48-md {
  margin-left: 3.4285714286rem !important;
}

.margin-left-48-lg {
  margin-left: 3.4285714286rem !important;
}

.margin-bottom-48 {
  margin-bottom: 3.4285714286rem !important;
}

.margin-bottom-48-md {
  margin-bottom: 3.4285714286rem !important;
}

.margin-bottom-48-lg {
  margin-bottom: 3.4285714286rem !important;
}

.margin-right-48 {
  margin-right: 3.4285714286rem !important;
}

.margin-right-48-md {
  margin-right: 3.4285714286rem !important;
}

.margin-right-48-lg {
  margin-right: 3.4285714286rem !important;
}

.margin-top-xxl-4 {
  margin-top: 4.2857142857rem !important;
}

.margin-top-xxl-4-md {
  margin-top: 4.2857142857rem !important;
}

.margin-top-xxl-4-lg {
  margin-top: 4.2857142857rem !important;
}

.margin-left-xxl-4 {
  margin-left: 4.2857142857rem !important;
}

.margin-left-xxl-4-md {
  margin-left: 4.2857142857rem !important;
}

.margin-left-xxl-4-lg {
  margin-left: 4.2857142857rem !important;
}

.margin-bottom-xxl-4 {
  margin-bottom: 4.2857142857rem !important;
}

.margin-bottom-xxl-4-md {
  margin-bottom: 4.2857142857rem !important;
}

.margin-bottom-xxl-4-lg {
  margin-bottom: 4.2857142857rem !important;
}

.margin-right-xxl-4 {
  margin-right: 4.2857142857rem !important;
}

.margin-right-xxl-4-md {
  margin-right: 4.2857142857rem !important;
}

.margin-right-xxl-4-lg {
  margin-right: 4.2857142857rem !important;
}

.margin-top-60 {
  margin-top: 4.2857142857rem !important;
}

.margin-top-60-md {
  margin-top: 4.2857142857rem !important;
}

.margin-top-60-lg {
  margin-top: 4.2857142857rem !important;
}

.margin-left-60 {
  margin-left: 4.2857142857rem !important;
}

.margin-left-60-md {
  margin-left: 4.2857142857rem !important;
}

.margin-left-60-lg {
  margin-left: 4.2857142857rem !important;
}

.margin-bottom-60 {
  margin-bottom: 4.2857142857rem !important;
}

.margin-bottom-60-md {
  margin-bottom: 4.2857142857rem !important;
}

.margin-bottom-60-lg {
  margin-bottom: 4.2857142857rem !important;
}

.margin-right-60 {
  margin-right: 4.2857142857rem !important;
}

.margin-right-60-md {
  margin-right: 4.2857142857rem !important;
}

.margin-right-60-lg {
  margin-right: 4.2857142857rem !important;
}

.margin-top-xxl-5 {
  margin-top: 5.1428571429rem !important;
}

.margin-top-xxl-5-md {
  margin-top: 5.1428571429rem !important;
}

.margin-top-xxl-5-lg {
  margin-top: 5.1428571429rem !important;
}

.margin-left-xxl-5 {
  margin-left: 5.1428571429rem !important;
}

.margin-left-xxl-5-md {
  margin-left: 5.1428571429rem !important;
}

.margin-left-xxl-5-lg {
  margin-left: 5.1428571429rem !important;
}

.margin-bottom-xxl-5 {
  margin-bottom: 5.1428571429rem !important;
}

.margin-bottom-xxl-5-md {
  margin-bottom: 5.1428571429rem !important;
}

.margin-bottom-xxl-5-lg {
  margin-bottom: 5.1428571429rem !important;
}

.margin-right-xxl-5 {
  margin-right: 5.1428571429rem !important;
}

.margin-right-xxl-5-md {
  margin-right: 5.1428571429rem !important;
}

.margin-right-xxl-5-lg {
  margin-right: 5.1428571429rem !important;
}

.margin-top-72 {
  margin-top: 5.1428571429rem !important;
}

.margin-top-72-md {
  margin-top: 5.1428571429rem !important;
}

.margin-top-72-lg {
  margin-top: 5.1428571429rem !important;
}

.margin-left-72 {
  margin-left: 5.1428571429rem !important;
}

.margin-left-72-md {
  margin-left: 5.1428571429rem !important;
}

.margin-left-72-lg {
  margin-left: 5.1428571429rem !important;
}

.margin-bottom-72 {
  margin-bottom: 5.1428571429rem !important;
}

.margin-bottom-72-md {
  margin-bottom: 5.1428571429rem !important;
}

.margin-bottom-72-lg {
  margin-bottom: 5.1428571429rem !important;
}

.margin-right-72 {
  margin-right: 5.1428571429rem !important;
}

.margin-right-72-md {
  margin-right: 5.1428571429rem !important;
}

.margin-right-72-lg {
  margin-right: 5.1428571429rem !important;
}

.margin-top-xxl-6 {
  margin-top: 6rem !important;
}

.margin-top-xxl-6-md {
  margin-top: 6rem !important;
}

.margin-top-xxl-6-lg {
  margin-top: 6rem !important;
}

.margin-left-xxl-6 {
  margin-left: 6rem !important;
}

.margin-left-xxl-6-md {
  margin-left: 6rem !important;
}

.margin-left-xxl-6-lg {
  margin-left: 6rem !important;
}

.margin-bottom-xxl-6 {
  margin-bottom: 6rem !important;
}

.margin-bottom-xxl-6-md {
  margin-bottom: 6rem !important;
}

.margin-bottom-xxl-6-lg {
  margin-bottom: 6rem !important;
}

.margin-right-xxl-6 {
  margin-right: 6rem !important;
}

.margin-right-xxl-6-md {
  margin-right: 6rem !important;
}

.margin-right-xxl-6-lg {
  margin-right: 6rem !important;
}

.margin-top-84 {
  margin-top: 6rem !important;
}

.margin-top-84-md {
  margin-top: 6rem !important;
}

.margin-top-84-lg {
  margin-top: 6rem !important;
}

.margin-left-84 {
  margin-left: 6rem !important;
}

.margin-left-84-md {
  margin-left: 6rem !important;
}

.margin-left-84-lg {
  margin-left: 6rem !important;
}

.margin-bottom-84 {
  margin-bottom: 6rem !important;
}

.margin-bottom-84-md {
  margin-bottom: 6rem !important;
}

.margin-bottom-84-lg {
  margin-bottom: 6rem !important;
}

.margin-right-84 {
  margin-right: 6rem !important;
}

.margin-right-84-md {
  margin-right: 6rem !important;
}

.margin-right-84-lg {
  margin-right: 6rem !important;
}

.margin-top-xxl-7 {
  margin-top: 6.8571428571rem !important;
}

.margin-top-xxl-7-md {
  margin-top: 6.8571428571rem !important;
}

.margin-top-xxl-7-lg {
  margin-top: 6.8571428571rem !important;
}

.margin-left-xxl-7 {
  margin-left: 6.8571428571rem !important;
}

.margin-left-xxl-7-md {
  margin-left: 6.8571428571rem !important;
}

.margin-left-xxl-7-lg {
  margin-left: 6.8571428571rem !important;
}

.margin-bottom-xxl-7 {
  margin-bottom: 6.8571428571rem !important;
}

.margin-bottom-xxl-7-md {
  margin-bottom: 6.8571428571rem !important;
}

.margin-bottom-xxl-7-lg {
  margin-bottom: 6.8571428571rem !important;
}

.margin-right-xxl-7 {
  margin-right: 6.8571428571rem !important;
}

.margin-right-xxl-7-md {
  margin-right: 6.8571428571rem !important;
}

.margin-right-xxl-7-lg {
  margin-right: 6.8571428571rem !important;
}

.margin-top-96 {
  margin-top: 6.8571428571rem !important;
}

.margin-top-96-md {
  margin-top: 6.8571428571rem !important;
}

.margin-top-96-lg {
  margin-top: 6.8571428571rem !important;
}

.margin-left-96 {
  margin-left: 6.8571428571rem !important;
}

.margin-left-96-md {
  margin-left: 6.8571428571rem !important;
}

.margin-left-96-lg {
  margin-left: 6.8571428571rem !important;
}

.margin-bottom-96 {
  margin-bottom: 6.8571428571rem !important;
}

.margin-bottom-96-md {
  margin-bottom: 6.8571428571rem !important;
}

.margin-bottom-96-lg {
  margin-bottom: 6.8571428571rem !important;
}

.margin-right-96 {
  margin-right: 6.8571428571rem !important;
}

.margin-right-96-md {
  margin-right: 6.8571428571rem !important;
}

.margin-right-96-lg {
  margin-right: 6.8571428571rem !important;
}

.padding-0 {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 768px) {
  .padding-0-md {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (min-width: 1140px) {
  .padding-0-lg {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.padding-y-0 {
  padding-top: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 768px) {
  .padding-y-0-md {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-0-lg {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.padding-top-0 {
  padding-bottom: 0;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-4 {
  padding-top: 0.2857142857rem;
  padding-bottom: 0.2857142857rem;
  padding-left: 0.2857142857rem;
  padding-right: 0.2857142857rem;
}

@media screen and (min-width: 768px) {
  .padding-4-md {
    padding-top: 0.2857142857rem;
    padding-bottom: 0.2857142857rem;
    padding-left: 0.2857142857rem;
    padding-right: 0.2857142857rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-4-lg {
    padding-top: 0.2857142857rem;
    padding-bottom: 0.2857142857rem;
    padding-left: 0.2857142857rem;
    padding-right: 0.2857142857rem;
  }
}

.padding-y-4 {
  padding-top: 0.2857142857rem;
  padding-bottom: 0.2857142857rem;
}

@media screen and (min-width: 768px) {
  .padding-y-4-md {
    padding-top: 0.2857142857rem;
    padding-bottom: 0.2857142857rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-4-lg {
    padding-top: 0.2857142857rem;
    padding-bottom: 0.2857142857rem;
  }
}

.padding-top-4 {
  padding-bottom: 0.2857142857rem;
}

.padding-bottom-4 {
  padding-bottom: 0.2857142857rem;
}

.padding-8 {
  padding-top: 0.5714285714rem;
  padding-bottom: 0.5714285714rem;
  padding-left: 0.5714285714rem;
  padding-right: 0.5714285714rem;
}

@media screen and (min-width: 768px) {
  .padding-8-md {
    padding-top: 0.5714285714rem;
    padding-bottom: 0.5714285714rem;
    padding-left: 0.5714285714rem;
    padding-right: 0.5714285714rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-8-lg {
    padding-top: 0.5714285714rem;
    padding-bottom: 0.5714285714rem;
    padding-left: 0.5714285714rem;
    padding-right: 0.5714285714rem;
  }
}

.padding-y-8 {
  padding-top: 0.5714285714rem;
  padding-bottom: 0.5714285714rem;
}

@media screen and (min-width: 768px) {
  .padding-y-8-md {
    padding-top: 0.5714285714rem;
    padding-bottom: 0.5714285714rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-8-lg {
    padding-top: 0.5714285714rem;
    padding-bottom: 0.5714285714rem;
  }
}

.padding-top-8 {
  padding-bottom: 0.5714285714rem;
}

.padding-bottom-8 {
  padding-bottom: 0.5714285714rem;
}

.padding-12 {
  padding-top: 0.8571428571rem;
  padding-bottom: 0.8571428571rem;
  padding-left: 0.8571428571rem;
  padding-right: 0.8571428571rem;
}

@media screen and (min-width: 768px) {
  .padding-12-md {
    padding-top: 0.8571428571rem;
    padding-bottom: 0.8571428571rem;
    padding-left: 0.8571428571rem;
    padding-right: 0.8571428571rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-12-lg {
    padding-top: 0.8571428571rem;
    padding-bottom: 0.8571428571rem;
    padding-left: 0.8571428571rem;
    padding-right: 0.8571428571rem;
  }
}

.padding-y-12 {
  padding-top: 0.8571428571rem;
  padding-bottom: 0.8571428571rem;
}

@media screen and (min-width: 768px) {
  .padding-y-12-md {
    padding-top: 0.8571428571rem;
    padding-bottom: 0.8571428571rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-12-lg {
    padding-top: 0.8571428571rem;
    padding-bottom: 0.8571428571rem;
  }
}

.padding-top-12 {
  padding-bottom: 0.8571428571rem;
}

.padding-bottom-12 {
  padding-bottom: 0.8571428571rem;
}

.padding-14 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media screen and (min-width: 768px) {
  .padding-14-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-14-lg {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.padding-y-14 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .padding-y-14-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-14-lg {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.padding-top-14 {
  padding-bottom: 1rem;
}

.padding-bottom-14 {
  padding-bottom: 1rem;
}

.padding-16 {
  padding-top: 1.1428571429rem;
  padding-bottom: 1.1428571429rem;
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
}

@media screen and (min-width: 768px) {
  .padding-16-md {
    padding-top: 1.1428571429rem;
    padding-bottom: 1.1428571429rem;
    padding-left: 1.1428571429rem;
    padding-right: 1.1428571429rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-16-lg {
    padding-top: 1.1428571429rem;
    padding-bottom: 1.1428571429rem;
    padding-left: 1.1428571429rem;
    padding-right: 1.1428571429rem;
  }
}

.padding-y-16 {
  padding-top: 1.1428571429rem;
  padding-bottom: 1.1428571429rem;
}

@media screen and (min-width: 768px) {
  .padding-y-16-md {
    padding-top: 1.1428571429rem;
    padding-bottom: 1.1428571429rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-16-lg {
    padding-top: 1.1428571429rem;
    padding-bottom: 1.1428571429rem;
  }
}

.padding-top-16 {
  padding-bottom: 1.1428571429rem;
}

.padding-bottom-16 {
  padding-bottom: 1.1428571429rem;
}

.padding-20 {
  padding-top: 1.4285714286rem;
  padding-bottom: 1.4285714286rem;
  padding-left: 1.4285714286rem;
  padding-right: 1.4285714286rem;
}

@media screen and (min-width: 768px) {
  .padding-20-md {
    padding-top: 1.4285714286rem;
    padding-bottom: 1.4285714286rem;
    padding-left: 1.4285714286rem;
    padding-right: 1.4285714286rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-20-lg {
    padding-top: 1.4285714286rem;
    padding-bottom: 1.4285714286rem;
    padding-left: 1.4285714286rem;
    padding-right: 1.4285714286rem;
  }
}

.padding-y-20 {
  padding-top: 1.4285714286rem;
  padding-bottom: 1.4285714286rem;
}

@media screen and (min-width: 768px) {
  .padding-y-20-md {
    padding-top: 1.4285714286rem;
    padding-bottom: 1.4285714286rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-20-lg {
    padding-top: 1.4285714286rem;
    padding-bottom: 1.4285714286rem;
  }
}

.padding-top-20 {
  padding-bottom: 1.4285714286rem;
}

.padding-bottom-20 {
  padding-bottom: 1.4285714286rem;
}

.padding-24 {
  padding-top: 1.7142857143rem;
  padding-bottom: 1.7142857143rem;
  padding-left: 1.7142857143rem;
  padding-right: 1.7142857143rem;
}

@media screen and (min-width: 768px) {
  .padding-24-md {
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
    padding-left: 1.7142857143rem;
    padding-right: 1.7142857143rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-24-lg {
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
    padding-left: 1.7142857143rem;
    padding-right: 1.7142857143rem;
  }
}

.padding-y-24 {
  padding-top: 1.7142857143rem;
  padding-bottom: 1.7142857143rem;
}

@media screen and (min-width: 768px) {
  .padding-y-24-md {
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-24-lg {
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
}

.padding-top-24 {
  padding-bottom: 1.7142857143rem;
}

.padding-bottom-24 {
  padding-bottom: 1.7142857143rem;
}

.padding-28 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media screen and (min-width: 768px) {
  .padding-28-md {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-28-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.padding-y-28 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .padding-y-28-md {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-28-lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.padding-top-28 {
  padding-bottom: 2rem;
}

.padding-bottom-28 {
  padding-bottom: 2rem;
}

.padding-32 {
  padding-top: 2.2857142857rem;
  padding-bottom: 2.2857142857rem;
  padding-left: 2.2857142857rem;
  padding-right: 2.2857142857rem;
}

@media screen and (min-width: 768px) {
  .padding-32-md {
    padding-top: 2.2857142857rem;
    padding-bottom: 2.2857142857rem;
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-32-lg {
    padding-top: 2.2857142857rem;
    padding-bottom: 2.2857142857rem;
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}

.padding-y-32 {
  padding-top: 2.2857142857rem;
  padding-bottom: 2.2857142857rem;
}

@media screen and (min-width: 768px) {
  .padding-y-32-md {
    padding-top: 2.2857142857rem;
    padding-bottom: 2.2857142857rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-32-lg {
    padding-top: 2.2857142857rem;
    padding-bottom: 2.2857142857rem;
  }
}

.padding-top-32 {
  padding-bottom: 2.2857142857rem;
}

.padding-bottom-32 {
  padding-bottom: 2.2857142857rem;
}

.padding-36 {
  padding-top: 2.5714285714rem;
  padding-bottom: 2.5714285714rem;
  padding-left: 2.5714285714rem;
  padding-right: 2.5714285714rem;
}

@media screen and (min-width: 768px) {
  .padding-36-md {
    padding-top: 2.5714285714rem;
    padding-bottom: 2.5714285714rem;
    padding-left: 2.5714285714rem;
    padding-right: 2.5714285714rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-36-lg {
    padding-top: 2.5714285714rem;
    padding-bottom: 2.5714285714rem;
    padding-left: 2.5714285714rem;
    padding-right: 2.5714285714rem;
  }
}

.padding-y-36 {
  padding-top: 2.5714285714rem;
  padding-bottom: 2.5714285714rem;
}

@media screen and (min-width: 768px) {
  .padding-y-36-md {
    padding-top: 2.5714285714rem;
    padding-bottom: 2.5714285714rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-36-lg {
    padding-top: 2.5714285714rem;
    padding-bottom: 2.5714285714rem;
  }
}

.padding-top-36 {
  padding-bottom: 2.5714285714rem;
}

.padding-bottom-36 {
  padding-bottom: 2.5714285714rem;
}

.padding-48 {
  padding-top: 3.4285714286rem;
  padding-bottom: 3.4285714286rem;
  padding-left: 3.4285714286rem;
  padding-right: 3.4285714286rem;
}

@media screen and (min-width: 768px) {
  .padding-48-md {
    padding-top: 3.4285714286rem;
    padding-bottom: 3.4285714286rem;
    padding-left: 3.4285714286rem;
    padding-right: 3.4285714286rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-48-lg {
    padding-top: 3.4285714286rem;
    padding-bottom: 3.4285714286rem;
    padding-left: 3.4285714286rem;
    padding-right: 3.4285714286rem;
  }
}

.padding-y-48 {
  padding-top: 3.4285714286rem;
  padding-bottom: 3.4285714286rem;
}

@media screen and (min-width: 768px) {
  .padding-y-48-md {
    padding-top: 3.4285714286rem;
    padding-bottom: 3.4285714286rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-48-lg {
    padding-top: 3.4285714286rem;
    padding-bottom: 3.4285714286rem;
  }
}

.padding-top-48 {
  padding-bottom: 3.4285714286rem;
}

.padding-bottom-48 {
  padding-bottom: 3.4285714286rem;
}

.padding-60 {
  padding-top: 4.2857142857rem;
  padding-bottom: 4.2857142857rem;
  padding-left: 4.2857142857rem;
  padding-right: 4.2857142857rem;
}

@media screen and (min-width: 768px) {
  .padding-60-md {
    padding-top: 4.2857142857rem;
    padding-bottom: 4.2857142857rem;
    padding-left: 4.2857142857rem;
    padding-right: 4.2857142857rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-60-lg {
    padding-top: 4.2857142857rem;
    padding-bottom: 4.2857142857rem;
    padding-left: 4.2857142857rem;
    padding-right: 4.2857142857rem;
  }
}

.padding-y-60 {
  padding-top: 4.2857142857rem;
  padding-bottom: 4.2857142857rem;
}

@media screen and (min-width: 768px) {
  .padding-y-60-md {
    padding-top: 4.2857142857rem;
    padding-bottom: 4.2857142857rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-60-lg {
    padding-top: 4.2857142857rem;
    padding-bottom: 4.2857142857rem;
  }
}

.padding-top-60 {
  padding-bottom: 4.2857142857rem;
}

.padding-bottom-60 {
  padding-bottom: 4.2857142857rem;
}

.padding-72 {
  padding-top: 5.1428571429rem;
  padding-bottom: 5.1428571429rem;
  padding-left: 5.1428571429rem;
  padding-right: 5.1428571429rem;
}

@media screen and (min-width: 768px) {
  .padding-72-md {
    padding-top: 5.1428571429rem;
    padding-bottom: 5.1428571429rem;
    padding-left: 5.1428571429rem;
    padding-right: 5.1428571429rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-72-lg {
    padding-top: 5.1428571429rem;
    padding-bottom: 5.1428571429rem;
    padding-left: 5.1428571429rem;
    padding-right: 5.1428571429rem;
  }
}

.padding-y-72 {
  padding-top: 5.1428571429rem;
  padding-bottom: 5.1428571429rem;
}

@media screen and (min-width: 768px) {
  .padding-y-72-md {
    padding-top: 5.1428571429rem;
    padding-bottom: 5.1428571429rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-72-lg {
    padding-top: 5.1428571429rem;
    padding-bottom: 5.1428571429rem;
  }
}

.padding-top-72 {
  padding-bottom: 5.1428571429rem;
}

.padding-bottom-72 {
  padding-bottom: 5.1428571429rem;
}

.padding-84 {
  padding-top: 6rem;
  padding-bottom: 6rem;
  padding-left: 6rem;
  padding-right: 6rem;
}

@media screen and (min-width: 768px) {
  .padding-84-md {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-84-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

.padding-y-84 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media screen and (min-width: 768px) {
  .padding-y-84-md {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-84-lg {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.padding-top-84 {
  padding-bottom: 6rem;
}

.padding-bottom-84 {
  padding-bottom: 6rem;
}

.padding-96 {
  padding-top: 6.8571428571rem;
  padding-bottom: 6.8571428571rem;
  padding-left: 6.8571428571rem;
  padding-right: 6.8571428571rem;
}

@media screen and (min-width: 768px) {
  .padding-96-md {
    padding-top: 6.8571428571rem;
    padding-bottom: 6.8571428571rem;
    padding-left: 6.8571428571rem;
    padding-right: 6.8571428571rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-96-lg {
    padding-top: 6.8571428571rem;
    padding-bottom: 6.8571428571rem;
    padding-left: 6.8571428571rem;
    padding-right: 6.8571428571rem;
  }
}

.padding-y-96 {
  padding-top: 6.8571428571rem;
  padding-bottom: 6.8571428571rem;
}

@media screen and (min-width: 768px) {
  .padding-y-96-md {
    padding-top: 6.8571428571rem;
    padding-bottom: 6.8571428571rem;
  }
}

@media screen and (min-width: 1140px) {
  .padding-y-96-lg {
    padding-top: 6.8571428571rem;
    padding-bottom: 6.8571428571rem;
  }
}

.padding-top-96 {
  padding-bottom: 6.8571428571rem;
}

.padding-bottom-96 {
  padding-bottom: 6.8571428571rem;
}

.font-size-12 {
  font-size: 0.8571428571rem !important;
}

.font-size-14 {
  font-size: 1rem !important;
}

.font-size-17 {
  font-size: 1.2142857143rem !important;
}

.font-size-21 {
  font-size: 1.5rem !important;
}

.font-size-24 {
  font-size: 1.7142857143rem !important;
}

.font-size-32 {
  font-size: 2.2857142857rem !important;
}

.font-size-35 {
  font-size: 2.5rem !important;
}

.font-size-36 {
  font-size: 2.5714285714rem !important;
}

.font-size-40 {
  font-size: 2.8571428571rem !important;
}

.font-size-48 {
  font-size: 3.4285714286rem !important;
}

.font-size-60 {
  font-size: 4.2857142857rem !important;
}

.font-size-72 {
  font-size: 5.1428571429rem !important;
}

.font-size-80 {
  font-size: 5.7142857143rem !important;
}

.font-weight-light {
  font-weight: light !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-semibold {
  font-weight: semibold !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-style-normal {
  font-style: normal !important;
}

.font-style-italic {
  font-style: italic !important;
}

.font-style-oblique {
  font-style: oblique !important;
}

.font-style-initial {
  font-style: initial !important;
}

.font-style-inherit {
  font-style: inherit !important;
}

.text-align-center {
  text-align: center !important;
}

@media screen and (min-width: 768px) {
  .text-align-center-md {
    text-align: center !important;
  }
}

.text-align-left {
  text-align: left !important;
}

@media screen and (min-width: 768px) {
  .text-align-left-md {
    text-align: left !important;
  }
}

.text-align-right {
  text-align: right !important;
}

@media screen and (min-width: 768px) {
  .text-align-right-md {
    text-align: right !important;
  }
}

.text-align-justify {
  text-align: justify !important;
}

@media screen and (min-width: 768px) {
  .text-align-justify-md {
    text-align: justify !important;
  }
}

.font-family-Montserrat {
  font-family: Montserrat !important;
}

.color-soft-blue {
  color: #e3f2fd;
}

.background-color-soft-blue {
  background-color: #e3f2fd !important;
}

.fill-soft-blue {
  fill: #e3f2fd;
}

.border-color-soft-blue {
  border-color: #e3f2fd !important;
}

.color-pale-blue {
  color: #cae5fb;
}

.background-color-pale-blue {
  background-color: #cae5fb !important;
}

.fill-pale-blue {
  fill: #cae5fb;
}

.border-color-pale-blue {
  border-color: #cae5fb !important;
}

.color-clear-blue {
  color: #5388ff;
}

.background-color-clear-blue {
  background-color: #5388ff !important;
}

.fill-clear-blue {
  fill: #5388ff;
}

.border-color-clear-blue {
  border-color: #5388ff !important;
}

.color-lighter-blue {
  color: #2962ff;
}

.background-color-lighter-blue {
  background-color: #2962ff !important;
}

.fill-lighter-blue {
  fill: #2962ff;
}

.border-color-lighter-blue {
  border-color: #2962ff !important;
}

.color-light-blue {
  color: #2062d4;
}

.background-color-light-blue {
  background-color: #2062d4 !important;
}

.fill-light-blue {
  fill: #2062d4;
}

.border-color-light-blue {
  border-color: #2062d4 !important;
}

.color-base-blue {
  color: #003da5;
}

.background-color-base-blue {
  background-color: #003da5 !important;
}

.fill-base-blue {
  fill: #003da5;
}

.border-color-base-blue {
  border-color: #003da5 !important;
}

.color-medium-blue-two {
  color: #002688;
}

.background-color-medium-blue-two {
  background-color: #002688 !important;
}

.fill-medium-blue-two {
  fill: #002688;
}

.border-color-medium-blue-two {
  border-color: #002688 !important;
}

.color-dark-blue {
  color: #001770;
}

.background-color-dark-blue {
  background-color: #001770 !important;
}

.fill-dark-blue {
  fill: #001770;
}

.border-color-dark-blue {
  border-color: #001770 !important;
}

.color-darker-blue {
  color: #000548;
}

.background-color-darker-blue {
  background-color: #000548 !important;
}

.fill-darker-blue {
  fill: #000548;
}

.border-color-darker-blue {
  border-color: #000548 !important;
}

.color-light-pear {
  color: #d9ec5a;
}

.background-color-light-pear {
  background-color: #d9ec5a !important;
}

.fill-light-pear {
  fill: #d9ec5a;
}

.border-color-light-pear {
  border-color: #d9ec5a !important;
}

.color-base-pear {
  color: #cddc3a;
}

.background-color-base-pear {
  background-color: #cddc3a !important;
}

.fill-base-pear {
  fill: #cddc3a;
}

.border-color-base-pear {
  border-color: #cddc3a !important;
}

.color-medium-pear {
  color: #c0ca33;
}

.background-color-medium-pear {
  background-color: #c0ca33 !important;
}

.fill-medium-pear {
  fill: #c0ca33;
}

.border-color-medium-pear {
  border-color: #c0ca33 !important;
}

.color-dark-pear {
  color: #acbc00;
}

.background-color-dark-pear {
  background-color: #acbc00 !important;
}

.fill-dark-pear {
  fill: #acbc00;
}

.border-color-dark-pear {
  border-color: #acbc00 !important;
}

.color-soft-turquoise {
  color: #dcf8f4;
}

.background-color-soft-turquoise {
  background-color: #dcf8f4 !important;
}

.fill-soft-turquoise {
  fill: #dcf8f4;
}

.border-color-soft-turquoise {
  border-color: #dcf8f4 !important;
}

.color-light-turquoise {
  color: #b3ebf2;
}

.background-color-light-turquoise {
  background-color: #b3ebf2 !important;
}

.fill-light-turquoise {
  fill: #b3ebf2;
}

.border-color-light-turquoise {
  border-color: #b3ebf2 !important;
}

.color-base-turquoise {
  color: #80deea;
}

.background-color-base-turquoise {
  background-color: #80deea !important;
}

.fill-base-turquoise {
  fill: #80deea;
}

.border-color-base-turquoise {
  border-color: #80deea !important;
}

.color-medium-turquoise {
  color: #59ccde;
}

.background-color-medium-turquoise {
  background-color: #59ccde !important;
}

.fill-medium-turquoise {
  fill: #59ccde;
}

.border-color-medium-turquoise {
  border-color: #59ccde !important;
}

.color-soft-purple {
  color: #ebe8f9;
}

.background-color-soft-purple {
  background-color: #ebe8f9 !important;
}

.fill-soft-purple {
  fill: #ebe8f9;
}

.border-color-soft-purple {
  border-color: #ebe8f9 !important;
}

.color-blue-grey {
  color: #759bc6;
}

.background-color-blue-grey {
  background-color: #759bc6 !important;
}

.fill-blue-grey {
  fill: #759bc6;
}

.border-color-blue-grey {
  border-color: #759bc6 !important;
}

.color-dark-teal {
  color: #3f92a6;
}

.background-color-dark-teal {
  background-color: #3f92a6 !important;
}

.fill-dark-teal {
  fill: #3f92a6;
}

.border-color-dark-teal {
  border-color: #3f92a6 !important;
}

.color-soft-orange {
  color: #fef4ed;
}

.background-color-soft-orange {
  background-color: #fef4ed !important;
}

.fill-soft-orange {
  fill: #fef4ed;
}

.border-color-soft-orange {
  border-color: #fef4ed !important;
}

.color-light-orange {
  color: #f9a825;
}

.background-color-light-orange {
  background-color: #f9a825 !important;
}

.fill-light-orange {
  fill: #f9a825;
}

.border-color-light-orange {
  border-color: #f9a825 !important;
}

.color-dark-orange {
  color: #ef6d19;
}

.background-color-dark-orange {
  background-color: #ef6d19 !important;
}

.fill-dark-orange {
  fill: #ef6d19;
}

.border-color-dark-orange {
  border-color: #ef6d19 !important;
}

.color-soft-green {
  color: #eff6eb;
}

.background-color-soft-green {
  background-color: #eff6eb !important;
}

.fill-soft-green {
  fill: #eff6eb;
}

.border-color-soft-green {
  border-color: #eff6eb !important;
}

.color-pale-green {
  color: #edf7df;
}

.background-color-pale-green {
  background-color: #edf7df !important;
}

.fill-pale-green {
  fill: #edf7df;
}

.border-color-pale-green {
  border-color: #edf7df !important;
}

.color-light-green {
  color: #7cb342;
}

.background-color-light-green {
  background-color: #7cb342 !important;
}

.fill-light-green {
  fill: #7cb342;
}

.border-color-light-green {
  border-color: #7cb342 !important;
}

.color-dark-green {
  color: #318500;
}

.background-color-dark-green {
  background-color: #318500 !important;
}

.fill-dark-green {
  fill: #318500;
}

.border-color-dark-green {
  border-color: #318500 !important;
}

.color-soft-red {
  color: #fdeded;
}

.background-color-soft-red {
  background-color: #fdeded !important;
}

.fill-soft-red {
  fill: #fdeded;
}

.border-color-soft-red {
  border-color: #fdeded !important;
}

.color-light-red {
  color: #ff585d;
}

.background-color-light-red {
  background-color: #ff585d !important;
}

.fill-light-red {
  fill: #ff585d;
}

.border-color-light-red {
  border-color: #ff585d !important;
}

.color-dark-red {
  color: #e71313;
}

.background-color-dark-red {
  background-color: #e71313 !important;
}

.fill-dark-red {
  fill: #e71313;
}

.border-color-dark-red {
  border-color: #e71313 !important;
}

.color-grey-1 {
  color: #eff1f4;
}

.background-color-grey-1 {
  background-color: #eff1f4 !important;
}

.fill-grey-1 {
  fill: #eff1f4;
}

.border-color-grey-1 {
  border-color: #eff1f4 !important;
}

.color-grey-2 {
  color: #e0e4e9;
}

.background-color-grey-2 {
  background-color: #e0e4e9 !important;
}

.fill-grey-2 {
  fill: #e0e4e9;
}

.border-color-grey-2 {
  border-color: #e0e4e9 !important;
}

.color-grey-3 {
  color: #d3d8de;
}

.background-color-grey-3 {
  background-color: #d3d8de !important;
}

.fill-grey-3 {
  fill: #d3d8de;
}

.border-color-grey-3 {
  border-color: #d3d8de !important;
}

.color-grey-4 {
  color: #bbc2ca;
}

.background-color-grey-4 {
  background-color: #bbc2ca !important;
}

.fill-grey-4 {
  fill: #bbc2ca;
}

.border-color-grey-4 {
  border-color: #bbc2ca !important;
}

.color-grey-5 {
  color: #97a1b0;
}

.background-color-grey-5 {
  background-color: #97a1b0 !important;
}

.fill-grey-5 {
  fill: #97a1b0;
}

.border-color-grey-5 {
  border-color: #97a1b0 !important;
}

.color-font-black {
  color: #1b1b1b;
}

.background-color-font-black {
  background-color: #1b1b1b !important;
}

.fill-font-black {
  fill: #1b1b1b;
}

.border-color-font-black {
  border-color: #1b1b1b !important;
}

.color-font-grey {
  color: #6b6b6b;
}

.background-color-font-grey {
  background-color: #6b6b6b !important;
}

.fill-font-grey {
  fill: #6b6b6b;
}

.border-color-font-grey {
  border-color: #6b6b6b !important;
}

.color-control-grey {
  color: #7f7f7f;
}

.background-color-control-grey {
  background-color: #7f7f7f !important;
}

.fill-control-grey {
  fill: #7f7f7f;
}

.border-color-control-grey {
  border-color: #7f7f7f !important;
}

.color-ia-grey {
  color: #7c878e;
}

.background-color-ia-grey {
  background-color: #7c878e !important;
}

.fill-ia-grey {
  fill: #7c878e;
}

.border-color-ia-grey {
  border-color: #7c878e !important;
}

.color-soft-wealth {
  color: #f0f0ef;
}

.background-color-soft-wealth {
  background-color: #f0f0ef !important;
}

.fill-soft-wealth {
  fill: #f0f0ef;
}

.border-color-soft-wealth {
  border-color: #f0f0ef !important;
}

.color-pale-wealth {
  color: #e8e8e7;
}

.background-color-pale-wealth {
  background-color: #e8e8e7 !important;
}

.fill-pale-wealth {
  fill: #e8e8e7;
}

.border-color-pale-wealth {
  border-color: #e8e8e7 !important;
}

.color-lighter-wealth {
  color: #a39073;
}

.background-color-lighter-wealth {
  background-color: #a39073 !important;
}

.fill-lighter-wealth {
  fill: #a39073;
}

.border-color-lighter-wealth {
  border-color: #a39073 !important;
}

.color-light-wealth {
  color: #937d5a;
}

.background-color-light-wealth {
  background-color: #937d5a !important;
}

.fill-light-wealth {
  fill: #937d5a;
}

.border-color-light-wealth {
  border-color: #937d5a !important;
}

.color-base-wealth {
  color: #836c4b;
}

.background-color-base-wealth {
  background-color: #836c4b !important;
}

.fill-base-wealth {
  fill: #836c4b;
}

.border-color-base-wealth {
  border-color: #836c4b !important;
}

.color-dark-wealth {
  color: #765f40;
}

.background-color-dark-wealth {
  background-color: #765f40 !important;
}

.fill-dark-wealth {
  fill: #765f40;
}

.border-color-dark-wealth {
  border-color: #765f40 !important;
}

.color-base-white {
  color: #ffffff;
}

.background-color-base-white {
  background-color: #ffffff !important;
}

.fill-base-white {
  fill: #ffffff;
}

.border-color-base-white {
  border-color: #ffffff !important;
}

.color-base-black {
  color: #000000;
}

.background-color-base-black {
  background-color: #000000 !important;
}

.fill-base-black {
  fill: #000000;
}

.border-color-base-black {
  border-color: #000000 !important;
}

.color-baby-orange {
  color: #fff4de;
}

.background-color-baby-orange {
  background-color: #fff4de !important;
}

.fill-baby-orange {
  fill: #fff4de;
}

.border-color-baby-orange {
  border-color: #fff4de !important;
}

.sr-only {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.border-top-grey-2 {
  border-top: 1px solid #e0e4e9;
}

.border-top-blue-8 {
  border-top: 8px solid #003da5;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-60 {
  border-radius: 60px;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-none {
  display: none;
}

.display-none-en html[lang=en] {
  display: none;
}

.display-none-fr html[lang=fr] {
  display: none;
}

.permanent-display-none {
  display: none !important;
}

.display-none-sm {
  display: none;
}
@media screen and (min-width: 768px) {
  .display-none-sm {
    display: unset;
  }
}

.display-none-md {
  display: none;
}
@media screen and (min-width: 1140px) {
  .display-none-md {
    display: unset;
  }
}

.width-100,
.full-width {
  width: 100%;
}

.width-50,
.half-width {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .width-50,
  .half-width {
    width: 50%;
  }
}

.list-bullet li, .list-bullet-item {
  position: relative;
  padding-left: 20px;
}
.list-bullet li:not(:last-child), .list-bullet-item:not(:last-child) {
  margin-bottom: 12px !important;
}
.list-bullet li:before, .list-bullet-item:before {
  content: "•";
  position: absolute;
  top: 0;
  left: 0;
}

.list-icon li, .list-icon-item {
  position: relative;
  padding-left: 40px;
}
.list-icon li:not(:last-child), .list-icon-item:not(:last-child) {
  margin-bottom: 12px !important;
}
.list-icon li > [class*=material-icons], .list-icon-item > [class*=material-icons] {
  position: absolute;
  left: 0;
  color: #729eff;
  font-size: 24px;
}

@media print {
  @page {
    margin: 12pt 14pt 12pt 14pt;
    size: landscape;
    -webkit-print-color-adjust: exact;
  }
  body {
    zoom: 88%;
  }
  svg {
    fill: black !important;
  }
  .no-print {
    display: none;
  }
}
.row-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}
.row-flex > [class*=column-flex] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}
.row-flex.columns-xs {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.row-flex.columns-xs .column-flex-1-2, .row-flex.columns-xs .column-flex-1-3, .row-flex.columns-xs .column-flex-1-4, .row-flex.columns-xs .column-flex-1-5, .row-flex.columns-xs .column-flex-1-6 {
  width: calc(50% - 8px);
}
@media screen and (min-width: 768px) {
  .row-flex.columns-xs .column-flex-1-3, .row-flex.columns-xs .column-flex-1-5, .row-flex.columns-xs .column-flex-1-6 {
    width: calc(33.333% - 10.6666666667px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-xs .column-flex-1-4 {
    width: calc(25% - 12px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-xs .column-flex-1-5 {
    width: calc(20% - 12.8px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-xs .column-flex-1-6 {
    width: calc(16.667% - 13.3333333333px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-sm .column-flex-1-2 {
    width: calc(50% - 8px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-sm .column-flex-1-3 {
    width: calc(33.333% - 10.6666666667px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-sm .column-flex-1-4 {
    width: calc(25% - 12px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-sm .column-flex-1-5 {
    width: calc(20% - 12.8px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-sm .column-flex-1-6 {
    width: calc(16.667% - 13.3333333333px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-med {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-med .column-flex-1-2 {
    width: calc(50% - 8px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-med .column-flex-1-3 {
    width: calc(33.333% - 10.6666666667px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-med .column-flex-1-4 {
    width: calc(50% - 8px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-med .column-flex-1-4 {
    width: calc(25% - 12px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-med .column-flex-1-5 {
    width: calc(33.333% - 10.6666666667px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-med .column-flex-1-5 {
    width: calc(20% - 12.8px);
  }
}
@media screen and (min-width: 768px) {
  .row-flex.columns-med .column-flex-1-6 {
    width: calc(33.333% - 10.6666666667px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-med .column-flex-1-6 {
    width: calc(16.667% - 13.3333333333px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-lg .column-flex-1-2 {
    width: calc(50% - 8px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-lg .column-flex-1-3 {
    width: calc(33.333% - 10.6666666667px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-lg .column-flex-1-4 {
    width: calc(25% - 12px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-lg .column-flex-1-5 {
    width: calc(20% - 12.8px);
  }
}
@media screen and (min-width: 1140px) {
  .row-flex.columns-lg .column-flex-1-6 {
    width: calc(16.667% - 13.3333333333px);
  }
}

.ia-header-light {
  height: 58px;
  width: 100%;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
}
@media screen and (min-width: 768px) {
  .ia-header-light {
    height: 82px;
  }
}
.ia-header-light-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
}
@media screen and (min-width: 768px) {
  .ia-header-light-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2.2857142857rem;
    padding-right: 2.2857142857rem;
  }
}
.ia-header-light-logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 42px;
}
.ia-header-light-logo-container svg {
  width: 76px;
  height: 42px;
}
@media screen and (min-width: 768px) {
  .ia-header-light-logo-container {
    height: 50px;
  }
  .ia-header-light-logo-container svg {
    width: 94px;
    height: 50px;
  }
}
.ia-header-light-text-container {
  height: 100%;
  padding-left: 1.1428571429rem;
  margin-left: 1.1428571429rem;
  color: rgba(27, 27, 27, 0.65);
  border-left: 1px solid #e0e4e9;
  font-family: "Open Sans Semi Bold";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .ia-header-light-text-container {
    padding-left: 2.2857142857rem;
    margin-left: 2.2857142857rem;
  }
}
.ia-header-light-text {
  margin: 0;
  font-size: 1rem;
  line-height: 1.29;
}
@media screen and (min-width: 768px) {
  .ia-header-light-text {
    font-size: 1.2142857143rem;
  }
}

.ia-header-microsites {
  position: relative;
  min-height: 60px;
  background-color: #ffffff;
}
@media screen and (min-width: 1140px) {
  .ia-header-microsites {
    padding-top: 40px;
  }
}
.ia-header-container {
  max-width: 1140px;
  height: 100%;
  margin: auto;
}
@media screen and (min-width: 1140px) {
  .ia-header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 0;
  }
}
.ia-header-container .sub-menu {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: -150%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 24px;
  background: #fff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  z-index: 150;
}
@media screen and (min-width: 1140px) {
  .ia-header-container .sub-menu {
    display: none;
    width: 288px;
    height: auto;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    -webkit-transition: none;
    transition: none;
  }
}
.ia-header-container .sub-menu .menu-back {
  width: 100%;
  position: absolute;
  top: 24px;
  left: 0;
}
@media screen and (min-width: 1140px) {
  .ia-header-container .sub-menu .menu-back {
    display: none;
  }
}
.ia-header-container .sub-menu .button-back {
  color: #2062d4;
}
.ia-header-container .sub-menu .button-back svg {
  fill: #2062d4;
}
.ia-header-container .ia-header-nav-tab.selected .sub-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 0;
  gap: 24px;
}
@media screen and (min-width: 1140px) {
  .ia-header-container .ia-header-nav-tab.selected .sub-menu {
    border-bottom: 3px solid #003da5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    right: calc(-155px + 60%);
    top: 58px;
  }
}
.ia-header-logos-container {
  position: fixed;
  width: 100%;
  padding-left: 16px;
  background-color: #fff;
  margin: auto;
  height: 60px;
  z-index: 200;
}
@media screen and (min-width: 1140px) {
  .ia-header-logos-container {
    position: relative;
    width: auto;
    padding-left: 0;
  }
}
.ia-header-logos-container .logo-m {
  display: none;
}
@media screen and (min-width: 1140px) {
  .ia-header-logos-container .logo-m {
    display: block;
  }
}
.ia-header-logos-container .logo-m svg {
  width: 250px;
}
.ia-header-logos-container .logo-s {
  display: block;
  margin: 0;
}
@media screen and (min-width: 1140px) {
  .ia-header-logos-container .logo-s {
    display: none;
  }
}
.ia-header-logos-container .logo-s svg {
  width: 180px;
  margin-top: 9px;
}
.ia-header-nav-container {
  position: fixed;
  right: -100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  top: 60px;
  height: calc(100% - 60px);
  background-color: #fff;
  border-color: #d3d8de;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  z-index: 199;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-container {
    position: static;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    height: 100%;
    -webkit-transition: none;
    transition: none;
    top: auto;
  }
}
.nav-active .ia-header-nav-container {
  right: 0;
  border-top: 1px solid #d3d8de;
}
.ia-header-main-nav {
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 1140px) {
  .ia-header-main-nav {
    margin-left: 60px;
  }
}
.ia-header-main-nav .main-menu {
  position: relative;
  width: 50%;
  margin: auto;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
}
@media screen and (min-width: 1140px) {
  .ia-header-main-nav .main-menu {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
.ia-header-main-nav .ia-header-nav-tab {
  width: 100%;
}
@media screen and (min-width: 1140px) {
  .ia-header-main-nav .ia-header-nav-tab {
    position: relative;
    width: auto;
  }
}
.ia-header-mobile-nav {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 60px;
  z-index: 200;
}
@media screen and (min-width: 1140px) {
  .ia-header-mobile-nav {
    display: none;
  }
}
.ia-header-mobile-nav .toggle-mobile-nav {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  padding: 0;
  border: none;
  border-left: 1px solid #d3d8de;
  outline-offset: -4px;
  background: 0 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-header-mobile-nav .toggle-mobile-nav[aria-expanded=true] .icon-bar1 {
  -webkit-transform: translate3d(0, 7px, 0) rotate(45deg);
  transform: translate3d(0, 7px, 0) rotate(45deg);
}
.ia-header-mobile-nav .toggle-mobile-nav[aria-expanded=true] .icon-bar2 {
  opacity: 0;
}
.ia-header-mobile-nav .toggle-mobile-nav[aria-expanded=true] .icon-bar3 {
  -webkit-transform: translate3d(0, -7px, 0) rotate(-45deg);
  transform: translate3d(0, -7px, 0) rotate(-45deg);
}
.ia-header-mobile-nav .open-content {
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.ia-header-mobile-nav .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.ia-header-mobile-nav .icon-bar {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 0;
  background-color: #003da5;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-header-mobile-nav .icon-bar1 {
  top: 4px;
}
.ia-header-mobile-nav .icon-bar2 {
  top: 11px;
}
.ia-header-mobile-nav .icon-bar3 {
  top: 18px;
}
.ia-header-top-nav {
  width: 100%;
}
@media screen and (min-width: 1140px) {
  .ia-header-top-nav {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    background-color: #003da5;
    height: 40px;
    padding: 0 32px;
  }
}
.ia-header-top-nav .top-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-width: 1140px;
  margin: auto;
}
@media screen and (min-width: 1140px) {
  .ia-header-top-nav .top-menu {
    height: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.ia-header-top-nav .top-menu li {
  border-top: 1px solid #002688;
}
@media screen and (min-width: 1140px) {
  .ia-header-top-nav .top-menu li {
    border-right: 1px solid #002688;
    border-top: 0;
  }
}
.ia-header-top-nav .top-menu li:first-child {
  border-top: 0;
}
@media screen and (min-width: 1140px) {
  .ia-header-top-nav .top-menu li:first-child {
    border-left: 1px solid #002688;
  }
}
.ia-header-nav-tab {
  color: #6b6b6b;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: Open Sans;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab {
    border-bottom: 3px solid transparent;
    padding-bottom: 10px;
    margin: 31px 0 21px;
  }
}
.ia-header-nav-tab:hover, .ia-header-nav-tab:hover .link, .ia-header-nav-tab:hover svg {
  border-bottom-color: #2062d4;
  color: #2062d4;
  fill: #2062d4;
}
.ia-header-nav-tab:active, .ia-header-nav-tab:active .link, .ia-header-nav-tab:active svg {
  border-bottom-color: #2062d4;
  color: #2962ff;
  fill: #2962ff;
}
.ia-header-nav-tab.selected, .ia-header-nav-tab.selected .link, .ia-header-nav-tab.selected svg {
  border-bottom-color: #003da5;
  color: #003da5;
  fill: #003da5;
}
.ia-header-nav-tab.active, .ia-header-nav-tab.active .link, .ia-header-nav-tab.active svg {
  border-bottom-color: #003da5;
  color: #003da5;
  fill: #003da5;
}
.ia-header-nav-tab.sub-nav-item.selected {
  border-bottom-color: transparent;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab.selected .expand-less {
    display: block;
  }
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab.selected .expand-more {
    display: none;
  }
}
.ia-header-nav-tab .btn-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ia-header-nav-tab svg {
  position: static;
  margin-left: 6px;
}
.ia-header-nav-tab .link,
.ia-header-nav-tab .sub-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #1b1b1b;
  font-size: 1.2142857143rem;
  font-weight: 600;
  text-decoration: none;
  margin: 0;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab .link,
  .ia-header-nav-tab .sub-link {
    font-weight: 400;
  }
}
.ia-header-nav-tab .expand-less {
  display: none;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab .expand-less {
    display: none;
  }
}
.ia-header-nav-tab .expand-more {
  display: none;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab .expand-more {
    display: block;
  }
}
.ia-header-nav-tab .mobile-expand-more {
  display: block;
}
@media screen and (min-width: 1140px) {
  .ia-header-nav-tab .mobile-expand-more {
    display: none;
  }
}
.ia-header-nav-tab-sub {
  min-width: 108px;
  width: 100%;
}
.ia-header-nav-tab-sub:hover .sub-link {
  color: #2062d4;
  text-decoration: underline;
}
.ia-header-nav-tab-sub:active .sub-link {
  color: #2962ff;
}
.ia-header-nav-tab-sub.selected .sub-link {
  color: #003da5;
}
.ia-header-nav-tab-sub.active .sub-link {
  color: #003da5;
}

.ia-secondary-nav-tab {
  cursor: pointer;
  background-color: #003da5;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
}
.ia-secondary-nav-tab:hover, .ia-secondary-nav-tab.selected {
  background-color: #2062d4;
}
.ia-secondary-nav-tab:active {
  background-color: #2962ff;
}
.ia-secondary-nav-tab .btn-icon {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ia-secondary-nav-tab svg {
  position: static;
  margin-left: 6px;
}
.ia-secondary-nav-tab .link {
  padding: 11px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  margin: 0;
}
.ia-secondary-nav-tab .link:hover {
  text-decoration: underline;
}
.ia-secondary-nav-tab.client-space-section {
  background-color: #2062d4;
}

.ia-modal {
  position: fixed;
  max-width: 750px;
  z-index: 101;
  top: 0;
  left: 0;
  margin: 0 16px;
  display: none;
}
.ia-modal-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: none;
  background: rgba(0, 0, 0, 0.78);
}
.ia-modal-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.ia-modal-content {
  width: 100%;
}
.ia-breadcrumb-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 36px 16px;
  background-color: #ffffff;
}
@media screen and (min-width: 768px) {
  .ia-breadcrumb-container {
    padding: 36px 32px;
  }
}
.ia-breadcrumb-item {
  display: inline-block;
}
.ia-breadcrumb-item:not(:last-child):after {
  content: "\e60f";
  display: inline-block;
  margin: auto 6px;
  color: #1b1b1b;
  font-family: IA_icons;
  font-size: 9px;
  line-height: 2;
  font-weight: 600;
  vertical-align: middle;
  speak: none;
}
.ia-breadcrumb-link {
  font-weight: 600;
  text-decoration: none;
}

.ia-button-primary-text, .ia-modal-close {
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  color: #2062d4;
  font-size: 1.2142857143rem;
  font-weight: 600;
  letter-spacing: normal;
  text-decoration: none;
  cursor: pointer;
}
.ia-button-primary-text span, .ia-modal-close span {
  display: inline-block;
}
.ia-button-primary-text:hover span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-modal-close:hover span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-button-primary-text:focus span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-modal-close:focus span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-button-primary-text.disabled span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .disabled.ia-modal-close span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-button-primary-text:disabled span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-modal-close:disabled span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), .ia-button-primary-text[disabled] span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone), [disabled].ia-modal-close span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone) {
  position: relative;
}
.ia-button-primary-text:hover span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-modal-close:hover span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-button-primary-text:focus span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-modal-close:focus span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-button-primary-text.disabled span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .disabled.ia-modal-close span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-button-primary-text:disabled span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-modal-close:disabled span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, .ia-button-primary-text[disabled] span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before, [disabled].ia-modal-close span:first-of-type:not([class*=material-icons]):not([class*=social]):not(.icone):before {
  position: absolute;
  content: "";
  width: 100%;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background: #2062d4;
}
.ia-button-primary-text:hover, .ia-modal-close:hover {
  text-decoration: underline;
}
.ia-button-primary-text:focus, .ia-modal-close:focus {
  color: #2962ff;
}
.ia-button-primary-text:focus span:first-child:before, .ia-modal-close:focus span:first-child:before {
  background-color: #2962ff;
}
.ia-button-primary-text.disabled, .disabled.ia-modal-close, .ia-button-primary-text:disabled, .ia-modal-close:disabled, .ia-button-primary-text[disabled], [disabled].ia-modal-close {
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}
.ia-button-primary-text.disabled span:first-child:before, .disabled.ia-modal-close span:first-child:before, .ia-button-primary-text:disabled span:first-child:before, .ia-modal-close:disabled span:first-child:before, .ia-button-primary-text[disabled] span:first-child:before, [disabled].ia-modal-close span:first-child:before {
  background: transparent;
}
.ia-button-primary-text [class*=material-icons], .ia-modal-close [class*=material-icons] {
  position: static;
  display: inline-block;
  margin: 0 6px;
  font-size: 18px;
  line-height: 1.29;
  vertical-align: text-bottom;
  text-decoration: none !important;
}
.ia-button-primary-text.font-size-14 [class*=material-icons], .font-size-14.ia-modal-close [class*=material-icons] {
  font-size: 15px;
}
.ia-button-primary-text .icon:before, .ia-modal-close .icon:before {
  display: inline;
  margin-left: 6px;
}
.ia-button-primary-text-icon-before:before, .ia-button-primary-text.icon-before:before, .icon-before.ia-modal-close:before {
  display: inline-block;
  margin-right: 6px;
  font-family: IA_icons;
  font-size: 12px;
  line-height: 1;
  font-weight: normal;
  vertical-align: middle;
  speak: none;
}
.ia-button-primary-text-icon-before.ic-chevron-left:before, .ia-button-primary-text.icon-before.ic-chevron-left:before, .icon-before.ic-chevron-left.ia-modal-close:before {
  content: "\e60f";
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.ia-button-primary-text.reversed, .reversed.ia-modal-close, .ia-button-primary-text-reversed, .ia-button-primary-text.white, .white.ia-modal-close, .ia-button-primary-text-white {
  color: #ffffff !important;
  text-decoration: underline;
}
.ia-button-primary-text.reversed:hover, .reversed.ia-modal-close:hover, .ia-button-primary-text-reversed:hover, .ia-button-primary-text.white:hover, .white.ia-modal-close:hover, .ia-button-primary-text-white:hover {
  text-decoration: none;
}

.ia-button-primary-text-v2 {
  display: inline-block;
  padding: 0;
  background: transparent;
  border: none;
  color: #2062d4;
  font-size: 1.2142857143rem;
  font-weight: 600;
  letter-spacing: normal;
  text-decoration: none;
  cursor: pointer;
}
.ia-button-primary-text-v2:focus {
  color: #2962ff;
}
.ia-button-primary-text-v2.disabled, .ia-button-primary-text-v2:disabled, .ia-button-primary-text-v2[disabled] {
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}
.ia-button-primary-text-v2 [class*=material-icons] {
  display: inline;
  margin: 0 6px;
  font-size: 18px;
  line-height: 1.29;
  vertical-align: middle;
}
.ia-button-primary-text-v2:hover > .text {
  text-decoration: underline;
}

.ia-button-primary,
.ia-button-primary-solid {
  background: #003da5;
  border-radius: 3.5714285714rem;
  border: none;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: inline-block;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: normal;
  padding: 14px 32px;
  text-align: center;
  text-decoration: none;
}
.ia-button-primary [class*=material-icons],
.ia-button-primary-solid [class*=material-icons] {
  margin-left: 6px;
  font-size: 24px;
  line-height: 1rem;
  vertical-align: middle;
}
.ia-button-primary .icon:not([class*=material-icons]),
.ia-button-primary-solid .icon:not([class*=material-icons]) {
  display: block;
  line-height: 0;
}
.ia-button-primary .icon:not([class*=material-icons]):before,
.ia-button-primary-solid .icon:not([class*=material-icons]):before {
  line-height: 1.29;
}
.ia-button-primary .icon.left,
.ia-button-primary-solid .icon.left {
  margin-right: 6px;
}
.ia-button-primary .icon.right,
.ia-button-primary-solid .icon.right {
  margin-left: 6px;
}
.ia-button-primary svg,
.ia-button-primary-solid svg {
  fill: #ffffff;
}
.ia-button-primary:hover,
.ia-button-primary-solid:hover {
  background: #2062d4;
  color: #ffffff;
}
.ia-button-primary:focus, .ia-button-primary:active,
.ia-button-primary-solid:focus,
.ia-button-primary-solid:active {
  background: #2962ff;
  color: #ffffff;
}
.ia-button-primary.disabled, .ia-button-primary:disabled, .ia-button-primary[disabled],
.ia-button-primary-solid.disabled,
.ia-button-primary-solid:disabled,
.ia-button-primary-solid[disabled] {
  background: rgba(0, 0, 0, 0.3);
  cursor: default;
  pointer-events: none;
}
.ia-button-primary.full,
.ia-button-primary-solid.full {
  width: 100%;
}
.ia-button-primary.reversed, .ia-button-primary.white, .ia-button-primary-white, .ia-button-primary-reversed,
.ia-button-primary-solid.reversed,
.ia-button-primary-solid.white,
.ia-button-primary-solid-white,
.ia-button-primary-solid-reversed {
  background: #ffffff;
  color: #003da5;
}
.ia-button-primary.reversed svg, .ia-button-primary.white svg, .ia-button-primary-white svg, .ia-button-primary-reversed svg,
.ia-button-primary-solid.reversed svg,
.ia-button-primary-solid.white svg,
.ia-button-primary-solid-white svg,
.ia-button-primary-solid-reversed svg {
  fill: #003da5;
}
.ia-button-primary.reversed:hover, .ia-button-primary.white:hover, .ia-button-primary-white:hover, .ia-button-primary-reversed:hover,
.ia-button-primary-solid.reversed:hover,
.ia-button-primary-solid.white:hover,
.ia-button-primary-solid-white:hover,
.ia-button-primary-solid-reversed:hover {
  background: #e3f2fd;
  color: #2062d4;
}
.ia-button-primary.reversed:hover svg, .ia-button-primary.white:hover svg, .ia-button-primary-white:hover svg, .ia-button-primary-reversed:hover svg,
.ia-button-primary-solid.reversed:hover svg,
.ia-button-primary-solid.white:hover svg,
.ia-button-primary-solid-white:hover svg,
.ia-button-primary-solid-reversed:hover svg {
  fill: #2062d4;
}
.ia-button-primary.reversed:focus, .ia-button-primary.white:focus, .ia-button-primary-white:focus, .ia-button-primary-reversed:focus,
.ia-button-primary-solid.reversed:focus,
.ia-button-primary-solid.white:focus,
.ia-button-primary-solid-white:focus,
.ia-button-primary-solid-reversed:focus {
  background: #cae5fb;
  color: #2962ff;
}
.ia-button-primary.reversed:focus svg, .ia-button-primary.white:focus svg, .ia-button-primary-white:focus svg, .ia-button-primary-reversed:focus svg,
.ia-button-primary-solid.reversed:focus svg,
.ia-button-primary-solid.white:focus svg,
.ia-button-primary-solid-white:focus svg,
.ia-button-primary-solid-reversed:focus svg {
  fill: #2962ff;
}
.ia-button-primary.reversed.disabled, .ia-button-primary.reversed:disabled, .ia-button-primary.reversed[disabled], .ia-button-primary.white.disabled, .ia-button-primary.white:disabled, .ia-button-primary.white[disabled], .ia-button-primary-white.disabled, .ia-button-primary-white:disabled, .ia-button-primary-white[disabled], .ia-button-primary-reversed.disabled, .ia-button-primary-reversed:disabled, .ia-button-primary-reversed[disabled],
.ia-button-primary-solid.reversed.disabled,
.ia-button-primary-solid.reversed:disabled,
.ia-button-primary-solid.reversed[disabled],
.ia-button-primary-solid.white.disabled,
.ia-button-primary-solid.white:disabled,
.ia-button-primary-solid.white[disabled],
.ia-button-primary-solid-white.disabled,
.ia-button-primary-solid-white:disabled,
.ia-button-primary-solid-white[disabled],
.ia-button-primary-solid-reversed.disabled,
.ia-button-primary-solid-reversed:disabled,
.ia-button-primary-solid-reversed[disabled] {
  color: #bbc2ca;
}
.ia-button-primary.reversed.disabled svg, .ia-button-primary.reversed:disabled svg, .ia-button-primary.reversed[disabled] svg, .ia-button-primary.white.disabled svg, .ia-button-primary.white:disabled svg, .ia-button-primary.white[disabled] svg, .ia-button-primary-white.disabled svg, .ia-button-primary-white:disabled svg, .ia-button-primary-white[disabled] svg, .ia-button-primary-reversed.disabled svg, .ia-button-primary-reversed:disabled svg, .ia-button-primary-reversed[disabled] svg,
.ia-button-primary-solid.reversed.disabled svg,
.ia-button-primary-solid.reversed:disabled svg,
.ia-button-primary-solid.reversed[disabled] svg,
.ia-button-primary-solid.white.disabled svg,
.ia-button-primary-solid.white:disabled svg,
.ia-button-primary-solid.white[disabled] svg,
.ia-button-primary-solid-white.disabled svg,
.ia-button-primary-solid-white:disabled svg,
.ia-button-primary-solid-white[disabled] svg,
.ia-button-primary-solid-reversed.disabled svg,
.ia-button-primary-solid-reversed:disabled svg,
.ia-button-primary-solid-reversed[disabled] svg {
  fill: #bbc2ca;
}
.ia-button-primary.turquoise, .ia-button-primary-turquoise,
.ia-button-primary-solid.turquoise,
.ia-button-primary-solid-turquoise {
  background: #80deea;
  color: #003da5;
}
.ia-button-primary.turquoise:hover, .ia-button-primary-turquoise:hover,
.ia-button-primary-solid.turquoise:hover,
.ia-button-primary-solid-turquoise:hover {
  background: #bfeef4;
}
.ia-button-primary.turquoise:focus, .ia-button-primary-turquoise:focus,
.ia-button-primary-solid.turquoise:focus,
.ia-button-primary-solid-turquoise:focus {
  background: #a0e7f0;
}
.ia-button-primary.pear, .ia-button-primary-pear,
.ia-button-primary-solid.pear,
.ia-button-primary-solid-pear {
  background: #cddc3a;
  color: #003da5;
}
.ia-button-primary.pear:hover, .ia-button-primary.pear:focus, .ia-button-primary-pear:hover, .ia-button-primary-pear:focus,
.ia-button-primary-solid.pear:hover,
.ia-button-primary-solid.pear:focus,
.ia-button-primary-solid-pear:hover,
.ia-button-primary-solid-pear:focus {
  background: #d9ec5a;
}
.ia-button-primary.enabled,
.ia-button-primary-solid.enabled {
  background: #003da5;
}

.ia-button-primary-outlined {
  background: transparent;
  border: 0;
  color: #003da5;
  display: inline-block;
  font-size: 1.2142857143rem;
  font-weight: bold !important;
  letter-spacing: normal;
  padding: 14px 32px;
  position: relative;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  text-align: center;
  text-decoration: none;
  text-align: center;
}
.ia-button-primary-outlined:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #003da5;
  border-radius: 3.5714285714rem;
}
.ia-button-primary-outlined [class*=material-icons] {
  position: static;
  display: inline-block;
  margin: 0 6px;
  font-size: 24px;
  line-height: 1;
  vertical-align: text-bottom;
}
.ia-button-primary-outlined .icon {
  display: block;
  line-height: 0;
}
.ia-button-primary-outlined .icon:before {
  line-height: 1.29;
}
.ia-button-primary-outlined .icon.left {
  margin-right: 6px;
}
.ia-button-primary-outlined .icon.right {
  margin-left: 6px;
}
.ia-button-primary-outlined svg {
  fill: #003da5;
}
.ia-button-primary-outlined:hover {
  color: #2062d4;
}
.ia-button-primary-outlined:hover:before {
  border-color: #2062d4;
  border-width: 3px;
}
.ia-button-primary-outlined:hover svg {
  fill: #2062d4;
}
.ia-button-primary-outlined:active, .ia-button-primary-outlined:focus {
  color: #2962ff;
}
.ia-button-primary-outlined:active:before, .ia-button-primary-outlined:focus:before {
  border-color: #2962ff;
  border-width: 3px;
}
.ia-button-primary-outlined:active svg, .ia-button-primary-outlined:focus svg {
  fill: #2962ff;
}
.ia-button-primary-outlined.disabled, .ia-button-primary-outlined:disabled, .ia-button-primary-outlined[disabled] {
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}
.ia-button-primary-outlined.disabled:before, .ia-button-primary-outlined:disabled:before, .ia-button-primary-outlined[disabled]:before {
  border-color: #bbc2ca;
}
.ia-button-primary-outlined.disabled svg, .ia-button-primary-outlined:disabled svg, .ia-button-primary-outlined[disabled] svg {
  fill: #bbc2ca;
}
.ia-button-primary-outlined.full {
  width: 100%;
}
.ia-button-primary-outlined-icon:after, .ia-button-primary-outlined.icon:after {
  margin-left: 6px;
  font-family: IA_icons;
  font-size: 24px;
  line-height: 1;
  font-weight: normal;
  vertical-align: middle;
  speak: none;
}
.ia-button-primary-outlined-icon.ic-open-new:after, .ia-button-primary-outlined.icon.ic-open-new:after {
  content: "\e938";
}
.ia-button-primary-outlined-white, .ia-button-primary-outlined.white {
  background: transparent;
  color: #ffffff;
}
.ia-button-primary-outlined-white:before, .ia-button-primary-outlined.white:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #ffffff;
  border-radius: 3.5714285714rem;
}
.ia-button-primary-outlined-white svg, .ia-button-primary-outlined.white svg {
  fill: #ffffff;
}
.ia-button-primary-outlined-white:hover, .ia-button-primary-outlined.white:hover {
  color: #d9ec5a;
}
.ia-button-primary-outlined-white:hover:before, .ia-button-primary-outlined.white:hover:before {
  border: 3px solid #d9ec5a;
}
.ia-button-primary-outlined-white:hover svg, .ia-button-primary-outlined.white:hover svg {
  fill: #d9ec5a;
}
.ia-button-primary-outlined-white:focus, .ia-button-primary-outlined.white:focus {
  color: #e6fa5e;
}
.ia-button-primary-outlined-white:focus:before, .ia-button-primary-outlined.white:focus:before {
  border: 3px solid #e6fa5e;
}
.ia-button-primary-outlined-white:focus svg, .ia-button-primary-outlined.white:focus svg {
  fill: #e6fa5e;
}
.ia-button-primary-outlined-white.disabled, .ia-button-primary-outlined-white:disabled, .ia-button-primary-outlined-white[disabled], .ia-button-primary-outlined.white.disabled, .ia-button-primary-outlined.white:disabled, .ia-button-primary-outlined.white[disabled] {
  color: #bbc2ca;
}
.ia-button-primary-outlined-white.disabled:before, .ia-button-primary-outlined-white:disabled:before, .ia-button-primary-outlined-white[disabled]:before, .ia-button-primary-outlined.white.disabled:before, .ia-button-primary-outlined.white:disabled:before, .ia-button-primary-outlined.white[disabled]:before {
  border: 2px solid #bbc2ca;
}
.ia-button-primary-outlined-white.disabled svg, .ia-button-primary-outlined-white:disabled svg, .ia-button-primary-outlined-white[disabled] svg, .ia-button-primary-outlined.white.disabled svg, .ia-button-primary-outlined.white:disabled svg, .ia-button-primary-outlined.white[disabled] svg {
  fill: #bbc2ca;
}

.ia-button-secondary,
.ia-button-secondary-solid {
  font-size: 1rem;
  border: none;
  background-color: #003da5;
  color: #ffffff;
  font-weight: bold;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
  display: inline-block;
  padding: 11px 16px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  text-align: center;
  text-decoration: none;
}
.ia-button-secondary:hover,
.ia-button-secondary-solid:hover {
  background-color: #2062d4;
  color: #ffffff;
}
.ia-button-secondary:focus,
.ia-button-secondary-solid:focus {
  background-color: #2962ff;
}
.ia-button-secondary.disabled, .ia-button-secondary:disabled, .ia-button-secondary[disabled],
.ia-button-secondary-solid.disabled,
.ia-button-secondary-solid:disabled,
.ia-button-secondary-solid[disabled] {
  background-color: #bbc2ca;
  cursor: pointer;
  pointer-events: none;
}
.ia-button-secondary.icon,
.ia-button-secondary-solid.icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ia-button-secondary.icon .icon,
.ia-button-secondary-solid.icon .icon {
  margin-left: 7px;
}
.ia-button-secondary.icon .icon.right,
.ia-button-secondary-solid.icon .icon.right {
  margin-left: 0.2857142857rem;
}

.ia-button-secondary-outlined {
  font-size: 1rem;
  position: relative;
  padding: 11px 16px;
  border: 0;
  background-color: transparent;
  color: #003da5;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}
.ia-button-secondary-outlined:before {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  border: 1px solid #003da5;
  bottom: 0;
  border-radius: 4px;
}
.ia-button-secondary-outlined:hover {
  color: #2062d4;
}
.ia-button-secondary-outlined:hover:before {
  border: 2px solid #2062d4;
  border-color: #2062d4;
}
.ia-button-secondary-outlined:hover + svg {
  fill: #2062d4;
}
.ia-button-secondary-outlined:focus {
  color: #2962ff;
}
.ia-button-secondary-outlined:focus:before {
  border-color: #2962ff;
}
.ia-button-secondary-outlined:focus + svg {
  fill: #2962ff;
}
.ia-button-secondary-outlined.disabled, .ia-button-secondary-outlined:disabled, .ia-button-secondary-outlined[disabled] {
  color: #bbc2ca;
  cursor: default;
}
.ia-button-secondary-outlined.disabled:hover, .ia-button-secondary-outlined:disabled:hover, .ia-button-secondary-outlined[disabled]:hover {
  border-color: #bbc2ca;
}
.ia-button-secondary-outlined.disabled:hover:before, .ia-button-secondary-outlined:disabled:hover:before, .ia-button-secondary-outlined[disabled]:hover:before {
  border: 1px solid #bbc2ca;
  border-color: #bbc2ca;
}
.ia-button-secondary-outlined.disabled + svg, .ia-button-secondary-outlined:disabled + svg, .ia-button-secondary-outlined[disabled] + svg {
  fill: #bbc2ca;
}
.ia-button-secondary-outlined.icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ia-button-secondary-outlined.icon i {
  margin-right: 0.2857142857rem;
  height: 100%;
  line-height: 1;
  font-size: 1.2857142857rem;
}
.ia-button-secondary-outlined.icon i.right {
  margin-left: 0.2857142857rem;
  margin-right: 0;
}
.ia-button-secondary-outlined.icon svg {
  margin-right: 0.2857142857rem;
  height: 1.1428571429rem;
  width: 1.1428571429rem;
  line-height: 1;
  fill: #003da5;
}
.ia-button-secondary-outlined.icon svg.right {
  margin-left: 0.2857142857rem;
  margin-right: 0;
}
.ia-button-secondary-outlined.icon svg {
  margin-right: 0.2857142857rem;
  height: spacing("m");
  width: spacing("m");
  fill: #003da5;
  line-height: 1;
}
.ia-button-secondary-outlined.icon svg.right {
  margin-left: 0.2857142857rem;
  margin-right: 0;
}

.ia-button-secondary-text {
  font-size: 1rem;
  position: relative;
  border: none;
  color: #2062d4;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}
.ia-button-secondary-text.white, .ia-button-secondary-text.white:active, .ia-button-secondary-text.white:hover {
  color: #ffffff;
}
.ia-button-secondary-text.white:hover {
  text-decoration: underline;
}
.ia-button-secondary-text .text:hover {
  text-decoration: underline;
}
.ia-button-secondary-text:active {
  color: #2962ff;
}
.ia-button-secondary-text.icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ia-button-secondary-text.icon i {
  margin-left: 0.2857142857rem;
  height: 100%;
  line-height: 1;
  font-size: 1.2857142857rem;
  text-decoration: none;
}

.ia-button-footer {
  background: #eff1f4;
  border-radius: 3.5714285714rem;
  border: solid 1px #eff1f4;
  color: #2062d4;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: normal;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
}
.ia-button-footer [class*=material-icons] {
  margin-left: 6px;
  font-size: 19px;
  vertical-align: middle;
}
.ia-button-footer .icon:not([class*=material-icons]) {
  display: block;
  line-height: 0;
}
.ia-button-footer .icon:not([class*=material-icons]):before {
  line-height: 1.29;
}
.ia-button-footer .icon.left {
  margin-right: 6px;
}
.ia-button-footer .icon.right {
  margin-left: 6px;
}
.ia-button-footer svg {
  position: relative;
  top: 3px;
  height: 1.1428571429rem;
  width: 1.1428571429rem;
  margin-top: -4px;
  fill: #2062d4;
}
.ia-button-footer:hover {
  border-color: #2062d4;
}
.ia-button-footer:focus, .ia-button-footer:active {
  border-color: #2962ff;
  color: #2962ff;
}
.ia-button-footer:focus svg, .ia-button-footer:active svg {
  fill: #2962ff;
}
.ia-button-footer.disabled, .ia-button-footer:disabled, .ia-button-footer[disabled] {
  background: rgba(0, 0, 0, 0.3);
  cursor: default;
  pointer-events: none;
  color: #bbc2ca;
  border-color: #bbc2ca;
}

.ia-button-inline-text {
  text-decoration: underline;
  color: #2062cb;
}
.ia-button-inline-text:hover {
  color: #3b5def;
}

.ia-card, .ia-card-corner, .ia-card-line {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 36px 16px;
  background-color: #fff;
  font-size: 17px;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .ia-card, .ia-card-corner, .ia-card-line {
    padding: 36px 32px;
  }
}
.ia-card-line {
  border-top: 8px solid #003da5;
}
.ia-card-line-icon {
  width: 48px;
  height: 48px;
}
.ia-card-line-icon svg {
  width: 48px;
  height: 48px;
  fill: #003da5;
}
.ia-card-line-title {
  margin: 0 0 24px;
  font-family: Montserrat, sans-serif;
  font-size: 21px;
  line-height: 1.24;
  font-weight: bold;
}
.ia-card-line-link {
  margin-top: auto;
}
.ia-card-corner:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 24px solid #3f92a6;
  border-left: 24px solid transparent;
}
.ia-card-corner-icon {
  width: 48px;
  height: 48px;
}
.ia-card-corner-icon svg {
  width: 48px;
  height: 48px;
  fill: #3f92a6;
}
.ia-card-corner-title {
  margin: 0 0 24px;
  font-family: Montserrat, sans-serif;
  font-size: 21px;
  line-height: 1.24;
  font-weight: bold;
}
.ia-card-corner-link {
  margin-top: auto;
}

.ia-chips {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: transparent;
  border: 1px solid #003da5;
  border-radius: 3.5714285714rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #003da5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0.8571428571rem;
  font-weight: 600;
  min-height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  text-decoration: none;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-property: color, border-color;
  transition-property: color, border-color;
}
.ia-chips.large {
  font-weight: bold;
  font-size: 1rem;
  min-height: 40px;
}
.ia-chips.selected:not(.disabled) {
  background-color: #e3f2fd;
  border: 2px solid #2062d4;
  color: #2062d4;
}
.ia-chips.selected:not(.disabled)::before {
  content: "\e876";
  font-family: "Material Icons Outlined";
  font-size: 19px;
  margin-right: 6px;
}
.ia-chips.unselected {
  border: 1px solid #7f7f7f;
  color: #6b6b6b;
}
.ia-chips:hover:not(.selected):not(.disabled) {
  border: 2px solid #2062d4;
  color: #2062d4;
  padding-left: 11px;
  padding-right: 11px;
}
.ia-chips:active:not(.selected):not(.disabled) {
  border: 2px solid #2962ff;
  color: #2962ff;
  padding-left: 11px;
  padding-right: 11px;
}
.ia-chips.disabled {
  border: 1px solid #bbc2ca;
  color: #bbc2ca;
  cursor: none;
}

.ia-custom-dropdown {
  position: relative;
}
.ia-custom-dropdown-label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 12px;
}
.ia-custom-dropdown-input {
  display: block;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  font-size: 14px;
  background-color: #ffffff;
  position: relative;
  cursor: pointer;
  padding: 11px 40px 11px 12px;
  line-height: 1.2;
}
.ia-custom-dropdown-input.error {
  border-color: #e71313;
  -webkit-box-shadow: inset 0 0 0 1px #e71313;
  box-shadow: inset 0 0 0 1px #e71313;
}
.ia-custom-dropdown-input .ia-custom-dropdown-icon {
  position: absolute;
  top: 7px;
  right: 8px;
  font-size: 24px;
  color: #003da5;
}
.ia-custom-dropdown-input .placeholder {
  color: #6b6b6b;
}
.ia-custom-dropdown-input:hover, .ia-custom-dropdown-input:focus {
  border-color: #2062d4;
}
.ia-custom-dropdown-input.open {
  border-color: #2962ff;
  -webkit-box-shadow: inset 0px 0px 0px 1px #2962ff;
  box-shadow: inset 0px 0px 0px 1px #2962ff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ia-custom-dropdown-input.open .ia-custom-dropdown-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ia-custom-dropdown-input.open + .ia-custom-dropdown-toggle {
  display: block;
}
.ia-custom-dropdown-toggle {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #7f7f7f;
  border-top: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  z-index: 10;
}
.ia-custom-dropdown-item {
  padding: 11px 12px;
  cursor: pointer;
}
.ia-custom-dropdown-item:hover, .ia-custom-dropdown-item:focus {
  background-color: #e3f2fd;
  color: #2062d4;
}
.ia-custom-dropdown-item.selected {
  background-color: #cae5fb;
  color: #003da5;
}

.ia-hero {
  padding: 60px 16px;
  background-position: center top;
  background-size: cover;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
@media only screen and (min-width: 768px) {
  .ia-hero {
    padding: 60px 32px;
  }
}
.ia-hero.full-screen {
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-hero-content {
  max-width: 1140px;
  margin: 0 auto;
}
.ia-hero-backlink {
  font-size: 14px;
}
.ia-hero-backlink:hover {
  text-decoration: none;
}
.ia-hero-text {
  max-width: 560px;
  padding: 48px 32px;
  background-color: rgba(0, 61, 165, 0.85);
}
.ia-hero-title {
  margin: 0 0 24px;
  color: #ffffff;
  font-size: 35px;
  font-weight: 900;
}
.ia-hero-subtitle {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
}
.ia-hero .ia-breadcrumb-container {
  padding: 0;
  background-color: transparent;
}

.ia-message {
  padding-left: 1.1428571429rem;
  padding-right: 1.1428571429rem;
  padding-top: 1.7142857143rem;
  padding-bottom: 1.7142857143rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #e3f2fd;
  border-radius: 0.2857142857rem;
  border: solid 1px #2062d4;
  color: #1b1b1b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  gap: 0.8571428571rem;
}
@media screen and (min-width: 768px) {
  .ia-message {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.ia-message .ia-message-text-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .ia-message .ia-message-text-container {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
}
.ia-message .ia-message-title {
  margin-bottom: 0.5714285714rem;
  color: #1b1b1b;
  font-family: "Open Sans";
  font-size: 1.2142857143rem;
  font-weight: bold;
}
.ia-message .ia-message-description {
  margin: 0;
  color: #1b1b1b;
  font-family: "Open Sans";
  font-size: 1rem;
}
.ia-message svg {
  fill: #2062d4;
  width: 3rem;
  height: 3rem;
}
.ia-message i,
.ia-message [class*=material-icon] {
  color: #2062d4;
  font-size: 48px;
}
.ia-message.warning {
  background: #fef4ed;
  border-color: #ef6d19;
}
.ia-message.warning svg {
  fill: #ef6d19;
}
.ia-message.warning i,
.ia-message.warning [class*=material-icon] {
  color: #ef6d19;
}
.ia-message.success {
  background: #eff6eb;
  border-color: #318500;
}
.ia-message.success svg {
  fill: #318500;
}
.ia-message.success i,
.ia-message.success [class*=material-icon] {
  color: #318500;
}
.ia-message.error {
  background: #fdeded;
  border-color: #e71313;
}
.ia-message.error svg {
  fill: #e71313;
}
.ia-message.error i,
.ia-message.error [class*=material-icon] {
  color: #e71313;
}

.ia-nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.ia-nav-tabs::-webkit-scrollbar {
  display: none;
}
@media screen and (min-width: 768px) {
  .ia-nav-tabs {
    gap: 24px;
  }
}

.ia-nav-tab {
  border-bottom: 3px solid transparent;
  color: #6b6b6b;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-family: Open Sans;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 13px;
  text-decoration: none;
}
.ia-nav-tab.selected, .ia-nav-tab.selected .link {
  border-bottom-color: #003da5;
  color: #003da5;
}
.ia-nav-tab.selected svg {
  fill: #003da5;
}
.ia-nav-tab:hover, .ia-nav-tab:hover .link {
  border-bottom-color: #2062d4;
  color: #2062d4;
}
.ia-nav-tab:hover svg {
  fill: #2062d4;
}
.ia-nav-tab:active, .ia-nav-tab:active .link {
  border-bottom-color: #2062d4;
  color: #2962ff;
}
.ia-nav-tab:active svg {
  fill: #2962ff;
}

.ia-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .ia-pagination {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
.ia-pagination .link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  text-decoration: none;
}
.ia-pagination .link.prev, .ia-pagination .link.next {
  border: 1px solid #003da5;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
}
.ia-pagination .link.prev > .material-icons-outlined, .ia-pagination .link.next > .material-icons-outlined {
  fill: #003da5;
}
.ia-pagination .link.prev.disabled, .ia-pagination .link.next.disabled {
  border-color: #c8ced4;
  cursor: default;
}
.ia-pagination .link.prev.disabled > .material-icons-outlined, .ia-pagination .link.next.disabled > .material-icons-outlined {
  color: #c8ced4;
}
.ia-pagination .link.prev:hover:not(.disabled), .ia-pagination .link.next:hover:not(.disabled) {
  border: 2px solid #2062d4;
  color: #2062d4;
}
.ia-pagination .link.prev:active:not(.disabled), .ia-pagination .link.next:active:not(.disabled) {
  border: 2px solid #2962ff;
  color: #2962ff;
}
.ia-pagination .link.prev {
  margin-right: 10px;
}
.ia-pagination .link.next {
  margin-left: 10px;
}
.ia-pagination .link.number, .ia-pagination .link.dots {
  border-top: 1px solid #7f7f7f;
  border-left: 1px solid #7f7f7f;
  border-bottom: 1px solid #7f7f7f;
  color: #6b6b6b;
  display: none;
  font-weight: bold;
  height: 40px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 40px;
}
@media screen and (min-width: 768px) {
  .ia-pagination .link.number, .ia-pagination .link.dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.ia-pagination .link.number:hover {
  border-color: #2062d4;
  border-right: 2px solid #2062d4;
  border-width: 2px;
  color: #2062d4;
}
.ia-pagination .link.number:hover + .number {
  border-left-width: 0px;
}
.ia-pagination .link.number:active {
  border-color: #2962ff;
  border-right: 2px solid #2962ff;
  border-width: 2px;
  color: #2962ff;
}
.ia-pagination .link.number:active + .number {
  border-left-width: 0px;
}
.ia-pagination .link.number.selected {
  background-color: #e3f2fd;
  border-color: #2062d4;
  border-right: 2px solid #2062d4;
  border-width: 2px;
  color: #2062d4;
}
.ia-pagination .link.number.selected + .number {
  border-left-width: 0px;
}
.ia-pagination .link.number:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.ia-pagination .link.number:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.ia-pagination .link.number:last-child:not(:hover):not(:active):not(.selected) {
  border-right: 1px solid #7f7f7f;
}
.ia-pagination .link.dots {
  cursor: default;
}

.numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}

.lds-spinner {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  color: transparent;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
}
.lds-spinner-container {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  margin: auto;
}
.lds-spinner div {
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  -webkit-animation: lds-spinner 1.2s linear infinite;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 37px;
  display: block;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.lds-spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.lds-spinner.dark div:after {
  background: #aaa;
}

@-webkit-keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ia-switch {
  height: 40px;
  padding: 11px 16px;
  border-radius: 4px;
  border: solid 1px #7f7f7f;
  background-color: #ffffff;
  color: #6b6b6b;
  font-size: 1rem;
}
.ia-switch:hover {
  color: #2062d4;
  border-color: #2062d4;
}
.ia-switch.enabled {
  color: #003da5;
  border-color: #003da5;
}
.ia-switch.enabled:hover {
  color: #2062d4;
  border-color: #2062d4;
}
.ia-switch:focus {
  color: #2962ff;
  border-color: #2962ff;
  outline: none;
}
.ia-switch.selected {
  color: #2062d4;
  background-color: #e3f2fd;
  border: 1px solid #2062d4;
}
.ia-switch.selected:before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid #2062d4;
}
.ia-switch.selected:focus {
  color: #2962ff;
  border-color: #2962ff;
}
.ia-switch.selected:focus:before {
  border-color: #2962ff;
}
.ia-switch.selected:hover {
  color: #2062d4;
  background-color: #ffffff;
  border-color: #2062d4;
}
.ia-switch.selected:hover:before {
  border-color: #2062d4;
}
.ia-switch[disabled],
.ia-switch .disabled {
  color: #bbc2ca;
  border: 1px solid #bbc2ca;
  background-color: #ffffff;
}

.ia-tag-outlined {
  background: transparent;
  border: 0;
  color: #003da5;
  display: inline-block;
  font-size: 0.8571428571rem;
  font-weight: 600 !important;
  letter-spacing: normal;
  padding: 7px 14px;
  position: relative;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  text-decoration: none;
}
.ia-tag-outlined:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #003da5;
  border-radius: 3.5714285714rem;
}
.ia-tag-outlined .material-icons {
  margin-left: 6px;
  font-size: 19px;
  vertical-align: middle;
}
.ia-tag-outlined .icon {
  display: block;
  line-height: 0;
}
.ia-tag-outlined .icon:before {
  line-height: 1.29;
}
.ia-tag-outlined .icon.left {
  margin-right: 6px;
}
.ia-tag-outlined .icon.right {
  margin-left: 6px;
}
.ia-tag-outlined svg {
  fill: #003da5;
}
.ia-tag-outlined:hover, .ia-tag-outlined:focus, .ia-tag-outlined.disabled, .ia-tag-outlined:disabled, .ia-tag-outlined[disabled] {
  outline: none;
}
.ia-tag-outlined:hover {
  color: #2062d4;
}
.ia-tag-outlined:hover:before {
  border-color: #2062d4;
}
.ia-tag-outlined:hover svg {
  fill: #2062d4;
}
.ia-tag-outlined:focus {
  color: #2962ff;
}
.ia-tag-outlined:focus:before {
  border-color: #2962ff;
}
.ia-tag-outlined:focus svg {
  fill: #2962ff;
}
.ia-tag-outlined.disabled, .ia-tag-outlined:disabled, .ia-tag-outlined[disabled] {
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}
.ia-tag-outlined.disabled:before, .ia-tag-outlined:disabled:before, .ia-tag-outlined[disabled]:before {
  border-color: #bbc2ca;
}
.ia-tag-outlined.disabled svg, .ia-tag-outlined:disabled svg, .ia-tag-outlined[disabled] svg {
  fill: #bbc2ca;
}
.ia-tag-outlined.full {
  width: 100%;
}
.ia-tag-outlined-icon:after, .ia-tag-outlined.icon:after {
  margin-left: 6px;
  font-family: IA_icons;
  font-size: 24px;
  line-height: 1;
  font-weight: normal;
  vertical-align: middle;
  speak: none;
}
.ia-tag-outlined-icon.ic-open-new:after, .ia-tag-outlined.icon.ic-open-new:after {
  content: "\e938";
}
.ia-tag-outlined-white, .ia-tag-outlined.white {
  background: transparent;
  color: #ffffff;
}
.ia-tag-outlined-white:before, .ia-tag-outlined.white:before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #ffffff;
  border-radius: 3.5714285714rem;
}
.ia-tag-outlined-white svg, .ia-tag-outlined.white svg {
  fill: #ffffff;
}
.ia-tag-outlined-white:hover, .ia-tag-outlined.white:hover {
  color: #d9ec5a;
}
.ia-tag-outlined-white:hover:before, .ia-tag-outlined.white:hover:before {
  border: 2px solid #d9ec5a;
}
.ia-tag-outlined-white:hover svg, .ia-tag-outlined.white:hover svg {
  fill: #d9ec5a;
}
.ia-tag-outlined-white:focus, .ia-tag-outlined.white:focus {
  outline: none;
  color: #e6fa5e;
}
.ia-tag-outlined-white:focus:before, .ia-tag-outlined.white:focus:before {
  border: 3px solid #e6fa5e;
}
.ia-tag-outlined-white:focus svg, .ia-tag-outlined.white:focus svg {
  fill: #e6fa5e;
}
.ia-tag-outlined-white.disabled, .ia-tag-outlined-white:disabled, .ia-tag-outlined-white[disabled], .ia-tag-outlined.white.disabled, .ia-tag-outlined.white:disabled, .ia-tag-outlined.white[disabled] {
  outline: none;
  color: #bbc2ca;
}
.ia-tag-outlined-white.disabled:before, .ia-tag-outlined-white:disabled:before, .ia-tag-outlined-white[disabled]:before, .ia-tag-outlined.white.disabled:before, .ia-tag-outlined.white:disabled:before, .ia-tag-outlined.white[disabled]:before {
  border: 2px solid #bbc2ca;
}
.ia-tag-outlined-white.disabled svg, .ia-tag-outlined-white:disabled svg, .ia-tag-outlined-white[disabled] svg, .ia-tag-outlined.white.disabled svg, .ia-tag-outlined.white:disabled svg, .ia-tag-outlined.white[disabled] svg {
  fill: #bbc2ca;
}

.team-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.team-group:not(:last-of-type) {
  margin-bottom: 36px;
}
.team-group [class*=grille-2-colonnes-flex] > .col-1-1,
.team-group [class*=grille-3-colonnes-flex] > .col-1-1 {
  float: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
  min-width: 100%;
  width: auto;
  margin-bottom: 0 !important;
}
@media screen and (min-width: 768px) {
  .team-group [class*=grille-2-colonnes-flex] > .col-1-1,
  .team-group [class*=grille-3-colonnes-flex] > .col-1-1 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .team-group [class*=grille-2-colonnes-flex] > .col-1-1 .team-member {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(50% - 16px);
  }
}
@media screen and (min-width: 768px) {
  .team-group [class*=grille-3-colonnes-flex] > .col-1-1 .team-member {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(33.333% - 16px);
  }
}
.team-title {
  color: #836c4b;
  font-family: "Open Sans", helvetica, arial, sans-serif;
  font-size: 1.2142857143rem;
  line-height: 1.29;
  font-weight: bold;
  text-transform: uppercase;
}
.team-member {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 16px;
  background-color: #f0f0ef;
}
@media screen and (min-width: 768px) {
  .team-member {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.team-member-img {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 16px 0 0;
}
.team-member-info {
  padding: 20px 0;
}
.team-member-name {
  margin: 0 0 4px;
  font-size: 1.5rem;
}
.team-member-title {
  display: block;
  margin: 0 0 4px;
  font-weight: 600;
}
.team-member-industry {
  display: block;
}

.ia-toggle {
  position: relative;
  display: inline-block;
  padding: 11px 16px;
  border: 1px solid #7f7f7f;
  background: transparent;
  color: #6b6b6b;
  font-size: 1rem;
  font-weight: bold !important;
  letter-spacing: normal;
  text-decoration: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:before {
  content: "";
  position: absolute;
  inset: -1px;
  border: 2px solid transparent;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:first-child, .ia-toggle:first-child:before, .ia-toggle:first-child.selected:before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:last-child, .ia-toggle:last-child:before, .ia-toggle:last-child.selected:before {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:hover {
  color: #2062d4;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:hover:before {
  border-color: #2062d4;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:active {
  color: #2962ff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle:active:before {
  border-color: #2962ff;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.ia-toggle.selected {
  background-color: #e3f2fd;
  color: #2062d4;
}
.ia-toggle.selected:before {
  content: "";
  position: absolute;
  inset: -1px;
  border: 2px solid #2062d4;
}
.ia-toggle:disabled, .ia-toggle.disabled {
  pointer-events: none;
  background-color: transparent;
  border-color: #bbc2ca;
  color: #bbc2ca;
}
.ia-toggle:disabled:before, .ia-toggle.disabled:before {
  border-color: transparent;
}
.ia-toggle + .ia-toggle {
  border-left: 0;
}
.ia-toggle-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ia-tooltip {
  position: absolute;
  max-width: 300px;
  padding: 12px;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.ia-tooltip.top:after, .ia-tooltip.top:before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 50%;
  height: 0;
  width: 0;
  border: solid transparent;
}
.ia-tooltip.top:after {
  margin-left: -6px;
  border-width: 6px;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
}
.ia-tooltip.top:before {
  margin-left: -7px;
  border-width: 7px;
  border-color: rgba(127, 127, 127, 0);
  border-top-color: #7f7f7f;
}
.ia-tooltip.right:after, .ia-tooltip.right:before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
}
.ia-tooltip.right:after {
  margin-top: -6px;
  border-width: 6px;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
}
.ia-tooltip.right:before {
  margin-top: -7px;
  border-width: 7px;
  border-color: rgba(127, 127, 127, 0);
  border-right-color: #7f7f7f;
}
.ia-tooltip.bottom:after, .ia-tooltip.bottom:before {
  content: "";
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 0;
  height: 0;
  border: solid transparent;
}
.ia-tooltip.bottom:after {
  margin-left: -12px;
  border-width: 12px;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
}
.ia-tooltip.bottom:before {
  margin-left: -13px;
  border-width: 13px;
  border-color: rgba(127, 127, 127, 0);
  border-bottom-color: #7f7f7f;
}
.ia-tooltip.left:after, .ia-tooltip.left:before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 100%;
  width: 0;
  height: 0;
  border: solid transparent;
}
.ia-tooltip.left:after {
  margin-top: -12px;
  border-width: 12px;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
}
.ia-tooltip.left:before {
  margin-top: -13px;
  border-width: 13px;
  border-color: rgba(127, 127, 127, 0);
  border-left-color: #7f7f7f;
}
.ia-tooltip-button {
  cursor: pointer;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  border: 0;
  background: transparent;
  line-height: 0;
  vertical-align: bottom;
}
.ia-tooltip-button [class*=material-icons] {
  pointer-events: none;
  color: #2062d4;
  font-size: 18px;
}

.ia-checkbox-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ia-checkbox-input {
  position: absolute;
  display: block;
  margin: 0;
  z-index: -1;
}
.ia-checkbox-input:focus-visible + .ia-checkbox-label {
  outline: -webkit-focus-ring-color auto 1px;
}
.ia-checkbox-input:checked + .ia-checkbox-label > .ia-checkbox-check::before {
  color: #003da5;
  content: "\e92c";
}
.ia-checkbox-check {
  position: absolute;
  top: 8px;
  left: 0;
  margin: 0;
}
.ia-checkbox-check:before {
  color: #7f7f7f;
  font-family: IA_icons;
  content: "\e92b";
  font-size: 18px;
}
.ia-checkbox-label {
  padding-left: 2.2857142857rem;
  padding-top: 0.5714285714rem;
  padding-bottom: 0.5714285714rem;
  position: relative;
}
.ia-checkbox-label:hover, .ia-checkbox-check:hover:before {
  color: #2062d4;
}
.ia-checkbox-label:active, .ia-checkbox-check:active:before {
  color: #2962ff;
}

.ia-distant-date-input {
  gap: 4px;
}
.ia-distant-date-input-day {
  width: 45px;
}
.ia-distant-date-input-month {
  width: 175px;
}
.ia-distant-date-input-year {
  width: 60px;
}
.ia-distant-date-input .ia-custom-dropdown-label {
  font-weight: normal;
}

input[type=text],
input[type=url],
input[type=email],
input[type=number] {
  -webkit-appearance: none;
}

.ia-input-default {
  font-size: 1rem;
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;
  border-radius: 0.2857142857rem;
  border: none;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  margin: 0;
  max-width: 100%;
  padding: 11px;
  line-height: 1.4;
  position: relative;
  display: block;
}
.ia-input-default:hover {
  -webkit-box-shadow: inset 0 0 0 1px #2062d4;
  box-shadow: inset 0 0 0 1px #2062d4;
}
.ia-input-default:hover + .icon:before,
.ia-input-default:hover + button > .icon:before {
  color: #2062d4;
}
.ia-input-default:focus {
  -webkit-box-shadow: inset 0 0 0 2px #2962ff;
  box-shadow: inset 0 0 0 2px #2962ff;
}
.ia-input-default:focus + .icon:before,
.ia-input-default:focus + button > .icon:before {
  color: #2062d4;
}
.ia-input-default.error {
  -webkit-box-shadow: inset 0 0 0 2px #e71313;
  box-shadow: inset 0 0 0 2px #e71313;
}
.ia-input-default[readonly] {
  background: #eff1f4;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  color: #1b1b1b;
}
.ia-input-default.disabled, .ia-input-default[disabled] {
  background: #eff1f4;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}

.ia-input-default.error + label.error,
.ia-input-default.error + span.error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.5714285714rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  color: #e71313;
  font-weight: 600;
}

.ia-input-group-container {
  position: relative;
}
.ia-input-group-container .icon {
  margin-right: 0.8571428571rem;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ia-input-group-container .icon:before {
  font-size: 1.7142857143rem;
  color: #003da5;
}
.ia-input-group-container button:not([class*=ia-button]) {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  border: none;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: none;
  position: absolute;
  right: 0;
  top: 0;
}
.ia-input-group-container button:not([class*=ia-button]) .icon {
  position: relative;
}

.ia-input-unit-container {
  position: relative;
}
.ia-input-unit-container .ia-input-default {
  width: 100%;
}
.ia-input-unit-container .label-placeholder {
  position: absolute;
  z-index: 1;
  top: 10px;
}
.ia-input-unit-container:not(.reverse) .ia-input-default {
  padding-right: 45px;
}
.ia-input-unit-container:not(.reverse) .label-placeholder {
  right: 12px;
}
.ia-input-unit-container.reverse .ia-input-default {
  padding-left: 55px;
  text-align: right;
}
.ia-input-unit-container.reverse .label-placeholder {
  left: 12px;
}

.ia-selectric label {
  font-size: 1rem;
  font-weight: bold;
}
.ia-selectric .selectric-hide-select {
  display: none;
}
.ia-selectric .selectric-disabled .selectric {
  background: #eff1f4;
  border: 1px solid #7f7f7f;
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}
.ia-selectric .selectric-disabled .selectric .button,
.ia-selectric .selectric-disabled .selectric .button::after {
  color: #bbc2ca;
  border-top-color: #bbc2ca;
}
.ia-selectric .selectric {
  background: #ffffff;
  border-radius: 0.2857142857rem;
  border: 1px solid #7f7f7f;
  height: 40px;
}
.ia-selectric .selectric:hover {
  border: 1px solid #2062d4;
}
.ia-selectric .selectric:hover + .icon:before,
.ia-selectric .selectric:hover + button > .icon:before {
  color: #2062d4;
}
.ia-selectric .selectric:focus {
  outline: none;
  border: 1px solid #2962ff;
  -webkit-box-shadow: inset 0 0 0 1px #2962ff;
  box-shadow: inset 0 0 0 1px #2962ff;
}
.ia-selectric .selectric:focus + .icon:before,
.ia-selectric .selectric:focus + button > .icon:before {
  color: #2062d4;
}
.ia-selectric .selectric[readonly] {
  background: #eff1f4;
  border: 1px solid #7f7f7f;
  color: #1b1b1b;
}
.ia-selectric .selectric.error {
  border: 1px solid #e71313;
  -webkit-box-shadow: inset 0 0 0 1px #e71313;
  box-shadow: inset 0 0 0 1px #e71313;
}
.ia-selectric .selectric .label {
  line-height: 40px;
  padding: 0 60px 0 12px;
  font-weight: normal;
}
.ia-selectric .selectric .label.default {
  color: #bbc2ca;
}
.ia-selectric .selectric .button,
.ia-selectric .selectric .button::after {
  color: #2062d4;
  border-top-color: #2062d4;
}
.ia-selectric .selectric .button::after {
  content: "\e936";
  font-family: IA_icons;
  font-size: 24px;
}
.ia-selectric .selectric-wrapper {
  position: relative;
}
.ia-selectric .selectric-wrapper .button {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 0;
}
.ia-selectric .selectric-wrapper .selectric-items {
  display: none;
  position: absolute;
  z-index: 1;
}
.ia-selectric .selectric-wrapper .selectric-scroll {
  border: 1px solid #7f7f7f;
}
.ia-selectric .selectric-wrapper.selectric-open .selectric-items {
  display: block;
}
.ia-selectric .selectric-wrapper.selectric-open .button {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ia-selectric .selectric-wrapper.selectric-open .selectric {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.ia-selectric .selectric-input {
  display: none;
}
.ia-selectric .selectric-items li {
  background-color: #ffffff;
  padding: 11px 12px;
}
.ia-selectric .selectric-items li:hover {
  background-color: #e3f2fd;
  color: #2062d4;
}
.ia-selectric .selectric-items li.selected:not(.disabled) {
  background-color: #cae5fb;
  color: #003da5;
}
.ia-selectric .selectric-items li.disabled {
  color: #bbc2ca;
}
.ia-selectric .selectric.error + span.error {
  font-size: 1rem;
  margin-top: 0.5714285714rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  color: #e71313;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
}
.ia-selectric .selectric.error + span.error:before {
  margin-right: 0.5714285714rem;
  content: "\e959";
  font-size: 1.3571428571rem;
  left: 0;
  position: relative;
  top: 0;
  -webkit-transform: none;
  transform: none;
}

.ia-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 11px 40px 11px 12px;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iIzIwNjJkNCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTcuNDEgOC41OSAxMiAxMy4xN2w0LjU5LTQuNThMMTggMTBsLTYgNi02LTYgMS40MS0xLjQxeiIvPjwvc3ZnPg==");
  background-size: 24px;
  background-position: right 8px center;
  background-repeat: no-repeat;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 1.4;
}
.ia-select:hover {
  -webkit-box-shadow: inset 0 0 0 1px #2062d4;
  box-shadow: inset 0 0 0 1px #2062d4;
}
.ia-select:focus {
  -webkit-box-shadow: inset 0 0 0 2px #2962ff;
  box-shadow: inset 0 0 0 2px #2962ff;
}
.ia-select.error {
  -webkit-box-shadow: inset 0 0 0 2px #e71313;
  box-shadow: inset 0 0 0 2px #e71313;
}

/*********************************** Breakpoints ************************************************/
/*********************************** Colors *****************************************************/
/*********************************** typography *****************************************************/
/*********************************** spacing *****************************************************/
/*********************************** Colors *****************************************************/
/*********************************** Colors *****************************************************/
.select-multiple {
  position: relative;
  width: 100%;
}
.select-multiple .select-label {
  display: block;
  margin-bottom: 12px;
  color: #1b1b1b;
  font-size: 14px;
  font-weight: bold;
}
.select-multiple .select-box {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 7px 8px 7px 12px;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
}
.select-multiple .select-box:hover, .select-multiple .select-box:focus-visible {
  border: 1px solid #2962ff;
}
.select-multiple .select-box span[class*=material] {
  color: #2062d4;
  font-size: 24px;
}
.select-multiple .options {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  display: none;
  width: 100%;
  height: 289px;
  border: 1px solid #7f7f7f;
  border-top: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: #ffffff;
  z-index: 1;
}
.select-multiple .options::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}
.select-multiple .options::-webkit-scrollbar-track {
  background-color: #fff;
}
.select-multiple .options::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}
.select-multiple .options::-webkit-scrollbar-button {
  display: none;
}
.select-multiple .options label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: normal;
}
.select-multiple .options label:hover {
  cursor: pointer;
  color: #2062d4;
}
.select-multiple .options label:hover input[type=checkbox] {
  outline: 1px solid #2062d4;
}
.select-multiple .options label input[type=checkbox] {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin: 3px;
}
.select-multiple .options label input[type=checkbox]:checked {
  accent-color: #2062d4;
}
.select-multiple .placeholder,
.select-multiple .one-option-selected,
.select-multiple .more-options-selected {
  display: none;
}
.select-multiple .placeholder {
  color: #6b6b6b;
}
.select-multiple .show {
  display: block;
}

.select-multiple.open .options {
  display: block;
}
.select-multiple.open .select-box {
  border: 1px solid #2962ff;
  -webkit-box-shadow: inset 0 0 0 1px #2062d4;
  box-shadow: inset 0 0 0 1px #2062d4;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
.select-multiple.open .select-box span[class*=material] {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

@media screen and (min-width: 768px) {
  .ia-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .ia-slider .ia-input-default-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 57px;
    flex: 0 0 57px;
  }
}
@media screen and (min-width: 768px) {
  .ia-slider .ia-input-unit-container {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 104px;
    flex: 0 0 104px;
  }
}
.ia-slider .ia-input-default {
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
@media screen and (min-width: 768px) {
  .ia-slider .ia-input-default {
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
}
.ia-slider-container {
  position: relative;
}
@media screen and (min-width: 768px) {
  .ia-slider-container {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }
}
.ia-slider-container .slider-track {
  position: relative;
}
.ia-slider-container .slider-horizontal {
  position: relative;
  top: calc(50% - 12px);
  height: 24px;
}
.ia-slider-container .slider-handle {
  width: 24px;
  height: 24px;
  margin-left: -12px;
  position: absolute;
  background: #003da5;
  border-radius: 50%;
}
.ia-slider-container .slider-selection, .ia-slider-container .slider-track-high {
  height: 2px;
  position: absolute;
  top: 12px;
}
.ia-slider-container .slider-selection {
  background: #5388ff;
}
.ia-slider-container .slider-track-high {
  background: #bbc2ca;
}
.ia-slider-container .hide {
  display: none;
}
.ia-slider-container:hover .slider-handle {
  background-color: #2062d4;
}
.ia-slider-container:active .slider-handle {
  background-color: #2962ff;
  border: solid 4px #91afff;
  width: 28px;
  height: 28px;
  top: -2px;
  margin-left: -14px;
}
.ia-slider-track, .ia-slider-track-input,
.ia-slider .ia-input-default {
  width: 100%;
}
.ia-slider-track {
  height: 40px;
  border: 1px solid #7f7f7f;
  -webkit-box-shadow: inset 0 0 1px 0px #7f7f7f;
  box-shadow: inset 0 0 1px 0px #7f7f7f;
  border-top: 0;
  background-color: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 24px;
}
@media screen and (min-width: 768px) {
  .ia-slider-track {
    height: 100%;
    border-top: 1px solid #7f7f7f;
    border-left: 0;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
}
.ia-slider.error {
  margin-bottom: 8px;
}
.ia-slider.error + span label.error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ia-slider.error .ia-slider-track {
  -webkit-box-shadow: inset 0px 0 0 1px #e71313;
  box-shadow: inset 0px 0 0 1px #e71313;
  border-color: #e71313;
}
.ia-slider.disabled .ia-slider-track {
  background-color: #eff1f4;
}
.ia-slider.disabled .slider-selection,
.ia-slider.disabled .slider-handle {
  background: #bbc2ca;
}
.ia-slider.disabled:hover .slider-handle {
  background-color: #bbc2ca;
}
.ia-slider.disabled:active .slider-handle {
  background-color: #bbc2ca;
  width: 24px;
  height: 24px;
  top: 0;
  margin-left: -12px;
  border: none;
}

textarea {
  -webkit-appearance: none;
}

.ia-textarea-default {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  background: #ffffff;
  border-top-left-radius: 0.2857142857rem;
  border-top-right-radius: 0.2857142857rem;
  border: none;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  height: 160px;
  margin: 0;
  max-width: 100%;
  padding: 12px 11px;
  position: relative;
  display: block;
}
.ia-textarea-default:hover {
  -webkit-box-shadow: inset 0 0 0 1px #2062d4;
  box-shadow: inset 0 0 0 1px #2062d4;
}
.ia-textarea-default:hover + .icon:before,
.ia-textarea-default:hover + button > .icon:before {
  color: #2062d4;
}
.ia-textarea-default:focus {
  outline: none;
  -webkit-box-shadow: inset 0 0 0 2px #2962ff;
  box-shadow: inset 0 0 0 2px #2962ff;
}
.ia-textarea-default:focus + .icon:before,
.ia-textarea-default:focus + button > .icon:before {
  color: #2062d4;
}
.ia-textarea-default.error {
  -webkit-box-shadow: inset 0 0 0 2px #e71313;
  box-shadow: inset 0 0 0 2px #e71313;
}
.ia-textarea-default[readonly] {
  background: #eff1f4;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  color: #1b1b1b;
}
.ia-textarea-default.disabled, .ia-textarea-default[disabled] {
  background: #eff1f4;
  -webkit-box-shadow: inset 0 0 0 1px #7f7f7f;
  box-shadow: inset 0 0 0 1px #7f7f7f;
  color: #bbc2ca;
  cursor: default;
  pointer-events: none;
}

.ia-textarea-default + .compteur {
  background-color: #eff1f4;
  border-bottom-left-radius: 0.2857142857rem;
  border-bottom-right-radius: 0.2857142857rem;
  padding: 8px 12px;
  text-align: right;
}

.ia-textarea-default.error + .compteur {
  background-color: #fdeded;
  padding: 8px 12px;
  text-align: right;
}

.ia-textarea-default.error + label.error,
.ia-textarea-default.error + span.error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  margin-top: 0.5714285714rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  color: #e71313;
  display: flex;
  font-weight: 600;
}

.ia-radio-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
}
.ia-radio-button .ia-radio-input:checked + .ia-radio-label {
  color: #2062d4;
  background-color: #e3f2fd;
  border: 1px solid #2062d4;
  -webkit-box-shadow: inset 0px 0px 0px 1px #2062d4;
  box-shadow: inset 0px 0px 0px 1px #2062d4;
}
.ia-radio-button .ia-radio-input:checked + .ia-radio-label > .ia-radio-check::before {
  color: #2062d4;
}
.ia-radio-button .ia-radio-check {
  top: 6px;
  left: 12px;
}
.ia-radio-button .ia-radio-check:before {
  color: #003da5;
}
.ia-radio-button .ia-radio-label {
  border: 1px solid #003da5;
  border-radius: 4px;
  padding: 10px 16px 10px 40px;
  line-height: 1.2;
  position: relative;
  color: #003da5;
  font-weight: 700;
  cursor: pointer;
}
.ia-radio-button .ia-radio-label:hover {
  color: #2062d4;
  border: 1px solid #2062d4;
  -webkit-box-shadow: inset 0px 0px 0px 1px #2062d4;
  box-shadow: inset 0px 0px 0px 1px #2062d4;
}
.ia-radio-button .ia-radio-label:hover .ia-radio-check::before {
  color: #2062d4;
}
.ia-radio-button .ia-radio-label:active {
  color: #2962ff;
  border: 1px solid #2962ff;
  -webkit-box-shadow: inset 0px 0px 0px 1px #2962ff;
  box-shadow: inset 0px 0px 0px 1px #2962ff;
}
.ia-radio-button .ia-radio-label:active .ia-radio-check::before {
  color: #2962ff;
}

.ia-radio-default {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ia-radio-input {
  position: absolute;
  display: block;
  margin: 0;
  opacity: 0;
  z-index: -1;
}
.ia-radio-input:focus-visible + .ia-radio-label {
  outline: -webkit-focus-ring-color auto 1px;
}
.ia-radio-input:checked + .ia-radio-label {
  color: #003da5;
}
.ia-radio-input:checked + .ia-radio-label > .ia-radio-check::before {
  color: #003da5;
  content: "\e92e";
}
.ia-radio-check {
  position: absolute;
  top: 6px;
  left: 0;
  margin: 0;
}
.ia-radio-check:before {
  color: #7f7f7f;
  font-family: IA_icons;
  content: "\e92d";
  font-size: 20px;
}
.ia-radio-label {
  padding-left: 2.2857142857rem;
  padding-top: 0.5714285714rem;
  padding-bottom: 0.5714285714rem;
  position: relative;
}
.ia-radio-label:hover, .ia-radio-check:hover:before {
  color: #2062d4;
}
.ia-radio-label:active, .ia-radio-check:active:before {
  color: #2962ff;
}

.ia-radio-group {
  border: solid 1px #7f7f7f;
  background-color: #ffffff;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ia-radio-group .ia-radio-label {
  padding-top: 0.8571428571rem;
  padding-bottom: 0.8571428571rem;
  padding-right: 0.8571428571rem;
  padding-left: 40px;
  width: 100%;
}
.ia-radio-group .ia-radio-label:hover {
  color: #2062d4;
  background-color: #e3f2fd;
}
.ia-radio-group .ia-radio-label:hover:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ia-radio-group .ia-radio-label:hover:last-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ia-radio-group .ia-radio-label:hover .ia-radio-check:before {
  color: #2062d4;
}
.ia-radio-group .ia-radio-label:active {
  color: #2962ff;
  background-color: #cae5fb;
}
.ia-radio-group .ia-radio-label:active .ia-radio-check:before {
  color: #2962ff;
}
.ia-radio-group .ia-radio-check {
  top: 12px;
  left: 12px;
}

.ia-footer-default {
  position: relative;
  margin-top: 4.2857142857rem;
  margin-bottom: 4.2857142857rem;
  margin-left: 1.1428571429rem;
  margin-right: 1.1428571429rem;
}
@media screen and (min-width: 1140px) {
  .ia-footer-default {
    margin-top: 4.2857142857rem;
    margin-bottom: 4.2857142857rem;
    margin-left: 2.2857142857rem;
    margin-right: 2.2857142857rem;
  }
}
.ia-footer-default-links {
  padding-top: 0;
  margin-bottom: 2.5714285714rem;
}
@media screen and (min-width: 768px) {
  .ia-footer-default-links {
    padding-top: 5px;
    width: calc(100% - 132px);
  }
}
.ia-footer-default-links .liens-principaux,
.ia-footer-default-links .liens-connexes,
.ia-footer-default-links .liens-secondaires {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .ia-footer-default-links .liens-principaux,
  .ia-footer-default-links .liens-connexes,
  .ia-footer-default-links .liens-secondaires {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 16px 32px;
  }
}
.ia-footer-default-links .liens-principaux .footer-link,
.ia-footer-default-links .liens-connexes .footer-link,
.ia-footer-default-links .liens-secondaires .footer-link {
  display: inline-block;
  width: calc(100% + 20px);
}
.ia-footer-default a[target=_blank]:after {
  content: "\e89e";
  display: inline-block;
  margin-left: 4px;
  font-family: "Material Icons";
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none !important;
  speak: none;
}

.ia-footer-default-langue-container {
  margin-bottom: 2.5714285714rem;
}
@media screen and (min-width: 768px) {
  .ia-footer-default-langue-container {
    position: absolute;
    right: 0;
    top: 0;
  }
}

h1 {
  margin: 0 0 24px;
  color: #003da5;
  font-family: Montserrat, sans-serif;
  font-size: 35px;
  line-height: 1.2;
  font-weight: 900;
}

h2 {
  margin: 0 0 24px;
  color: #1b1b1b;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  line-height: 1.19;
  font-weight: bold;
}

h3 {
  margin: 0 0 6px;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

p {
  margin: 0 0 16px;
  font-size: 17px;
  line-height: 1.29;
}

label {
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 1.29;
  font-weight: bold;
}

select {
  max-width: none;
  background-color: #fff;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.ia-message {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

#en-tete {
  min-height: 58px !important;
  height: auto;
  padding: 8px 16px;
}
@media screen and (min-width: 768px) {
  #en-tete {
    padding: 16px 32px;
  }
}
#en-tete .zone-toggle-menu {
  display: none;
}
#en-tete .inner {
  max-width: 1140px;
  margin: auto;
  padding: 0;
}
#en-tete .logo_ia_D {
  float: none;
  display: none;
  width: auto;
  margin: 0 !important;
  padding: 0;
}
@media screen and (min-width: 768px) {
  #en-tete .logo_ia_D {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
#en-tete .logo_ia_D a {
  pointer-events: none;
  display: block;
  line-height: 0;
}
#en-tete .logo_ia_D svg {
  width: 93.5px;
  height: 50px;
}
#en-tete .logo_ia_M {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: none;
  width: auto;
  margin: 0;
  padding: 0;
}
@media screen and (min-width: 768px) {
  #en-tete .logo_ia_M {
    display: none;
  }
}
#en-tete .logo_ia_M a {
  pointer-events: none;
  display: block;
  line-height: 0;
}
#en-tete .logo_ia_M svg {
  width: 78.9px;
  height: 42px;
  max-height: none;
}

#section-nav {
  display: none;
}

.footer-commun {
  padding: 60px 16px;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .footer-commun {
    padding: 60px 32px;
  }
}
.footer-commun .inner {
  position: relative;
  max-width: 1140px;
  margin: 0 auto;
}
.footer-commun .copy {
  padding: 0 !important;
}
.footer-commun .copy .ia-slogan {
  display: inline-block;
  margin: 0 0 36px !important;
  color: #7c878e;
  font-family: Montserrat, sans-serif;
  font-size: 24px !important;
  line-height: 1.17;
  font-weight: bold;
}
.footer-commun .copy .ia-copy p {
  margin: 0;
  color: #1b1b1b;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  line-height: 1.17;
  font-weight: 300;
}

.grille-section-evenements,
.grille-section-connexion {
  min-height: calc(100vh - 312px);
  padding: 60px 16px;
}
@media screen and (min-width: 768px) {
  .grille-section-evenements,
  .grille-section-connexion {
    padding: 96px 32px;
  }
}

.zone-pagination.margin-top-20 {
  margin-top: 36px !important;
}

.pagination {
  float: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}
.pagination-dropdown {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 180px;
  margin: 0 8px;
  padding: 10px 40px 10px 12px;
  border: solid 1px #7f7f7f;
  border-radius: 4px;
  outline: none;
  background: #fff;
}
.pagination-dropdown:hover, .pagination-dropdown:focus, .pagination-dropdown.open {
  border-color: #2062d4 !important;
  -webkit-box-shadow: inset 0 0 0 1px #2062d4 !important;
  box-shadow: inset 0 0 0 1px #2062d4 !important;
}
.pagination-dropdown:hover span, .pagination-dropdown:focus span, .pagination-dropdown.open span {
  color: #2062d4;
}
.pagination-dropdown:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -3px;
  border-width: 5px 5px 0 5px;
  border-style: solid;
  border-color: #2062d4 transparent;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
  -webkit-transition: -webkit-transform 0.25s ease;
  transition: -webkit-transform 0.25s ease;
  transition: transform 0.25s ease;
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}
.pagination-dropdown .page-item-group {
  display: none;
  position: absolute;
  top: 100%;
  max-height: 192px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 999;
  width: 100%;
  margin-top: -1px !important;
  border: solid 1px #7f7f7f;
  border-radius: 4px;
  background-color: #fff;
}
.pagination-dropdown .page-item-group::-webkit-scrollbar {
  width: 10px;
  border-radius: 4px;
  background-color: #f2f2f2;
}
.pagination-dropdown .page-item-group::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #f2f2f2;
}
.pagination-dropdown .page-item-group::-webkit-scrollbar-thumb {
  border: 2px solid #f2f2f2;
  background-color: #babac0;
  border-radius: 8px;
}
.pagination-dropdown .page-item-group::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
}
.pagination-dropdown .page-item-group::-webkit-scrollbar-button {
  display: none;
}
.pagination-dropdown.open .page-item-group {
  position: absolute;
  display: block;
}
.pagination-dropdown .page-btn {
  cursor: pointer;
  width: 100%;
  padding: 10px 12px;
  border: 0;
  background-color: transparent;
  text-align: left;
}
.pagination-dropdown .page-btn:hover, .pagination-dropdown .page-btn:focus {
  background-color: #e3f2fd;
  outline: none;
  color: #2062d4;
}
.pagination-dropdown .page-btn:active {
  background-color: #cae5fb;
  outline: none;
  color: #003da5;
}
.pagination-dropdown .page-btn.actif {
  pointer-events: none;
  color: rgba(27, 27, 27, 0.4);
}
.pagination-list {
  margin: 0 12px;
}
.pagination-list .page-item-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.pagination-list .page-item {
  margin: 0;
}
.pagination-list .page-item .page-btn-first {
  border-left: solid 1px #7f7f7f;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination-list .page-item .page-btn-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.pagination-list [class^=page-btn] {
  padding: 10px 14px;
  border-top: solid 1px #7f7f7f;
  border-bottom: solid 1px #7f7f7f;
  border-right: solid 1px #7f7f7f;
  border-left: solid 1px transparent;
  outline: none !important;
  background-color: #fff;
  color: #6b6b6b;
  font-size: 1rem;
  line-height: 1.29;
  font-weight: bold;
}
.pagination-list [class^=page-btn]:hover, .pagination-list [class^=page-btn]:focus, .pagination-list [class^=page-btn]:active, .pagination-list [class^=page-btn].actif {
  border-width: 1px !important;
  border-color: #2062d4 !important;
  -webkit-box-shadow: inset 0px 0px 0px 1px #2062d4 !important;
  box-shadow: inset 0px 0px 0px 1px #2062d4 !important;
  color: #2062d4;
}
.pagination-list [class^=page-btn].actif {
  background-color: #e3f2fd;
}
.pagination .page-prev,
.pagination .page-next {
  padding: 19px 18px;
  border: solid 1px #BBC2CA;
  border-radius: 4px;
  outline: none !important;
  background-color: #fff;
  background-image: url(/content/iaprivatewealth/img/chevron-grey.svg);
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  color: #BBC2CA;
  font-size: 1rem;
  line-height: 1.29;
  font-weight: bold;
}
.pagination .page-prev:not(:disabled),
.pagination .page-next:not(:disabled) {
  border-width: 1px !important;
  border-color: #003da5 !important;
  background-image: url(/content/iaprivatewealth/img/chevron-blue.svg);
  color: #003da5;
}
.pagination .page-prev:not(:disabled):hover, .pagination .page-prev:not(:disabled):focus, .pagination .page-prev:not(:disabled):active,
.pagination .page-next:not(:disabled):hover,
.pagination .page-next:not(:disabled):focus,
.pagination .page-next:not(:disabled):active {
  border-width: 1px !important;
  border-color: #2062d4 !important;
  -webkit-box-shadow: inset 0px 0px 0px 1px #2062d4 !important;
  box-shadow: inset 0px 0px 0px 1px #2062d4 !important;
  color: #2062d4;
}
.pagination .page-prev:disabled,
.pagination .page-next:disabled {
  border-width: 1px !important;
  border-color: #BBC2CA !important;
}
.pagination .page-prev {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.evt-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}
.evt-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding: 24px 16px;
  background: white;
}
@media screen and (min-width: 768px) {
  .evt-card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(50% - 8px);
    flex: 0 0 calc(50% - 8px);
    padding: 36px 32px;
  }
}
@media screen and (min-width: 1140px) {
  .evt-card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(33% - 8px);
    flex: 0 0 calc(33% - 8px);
    padding: 36px 32px;
  }
}
.evt-type {
  display: block;
  padding: 8px 12px;
  border: solid 1px #003da5;
  border-radius: 15px;
  color: #003da5;
  font-size: 12px;
  line-height: 1.17;
  font-weight: 600;
}
.evt-date {
  font-size: 14px;
  line-height: 1.29;
  font-weight: bold;
}
.evt-titre {
  font-size: 21px;
  line-height: 1.24;
  font-weight: 800;
}
.evt-section {
  font-size: 14px;
  line-height: 1.29;
  font-weight: bold;
}
.evt-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  dlex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  row-gap: 8px;
}
.evt-billets, .evt-stationnements, .evt-repas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 6px;
  -moz-column-gap: 6px;
  column-gap: 6px;
  color: #6b6b6b;
  font-size: 14px;
  line-height: 1.29;
  font-weight: 600;
}
.evt-billets [class*=material-icons], .evt-stationnements [class*=material-icons], .evt-repas [class*=material-icons] {
  font-size: 18px;
}
.evt-submit {
  display: block;
  width: 100%;
  margin-top: auto;
}

.connexion-form {
  margin: 0 !important;
}
.connexion-form h2 {
  max-width: 560px;
  margin: 0 auto 36px;
}
.connexion-form form {
  max-width: 560px;
  margin: 0 auto;
  padding: 36px 16px;
}
@media screen and (min-width: 768px) {
  .connexion-form form {
    padding: 36px 32px;
  }
}
.connexion-form .message-erreur {
  margin: 0 0 16px;
  font-size: 14px;
  font-weight: 600;
}
.connexion-form .champ-formulaire,
.connexion-form .section-soumettre {
  margin: 0;
}
.connexion-form .forgetmenot {
  display: none;
}

.form-select-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  row-gap: 24px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form-select-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.form-select-group .form-select-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.loadingGif {
  display: inline-block !important;
  position: relative;
  width: 40px;
  height: 1px;
}
.loadingGif:after {
  width: 32px;
  height: 25px;
  content: "";
  position: absolute;
  display: none;
  margin: 0;
  background: url("../../../content/commun/img/Loader-BG-white.gif") center center no-repeat;
}
.loadingGif.gris:after {
  background: url("../../../content/commun/img/Loader-BG-grey.gif") center center no-repeat;
}
.loadingGif.grisFonce:after {
  background: url("../../../content/commun/img/Loader-BG-Drak-grey.gif") center center no-repeat;
}
.loadingGif.bleuClair:after {
  background: url("../../../content/commun/img/Loader-BG-light-blue.gif") center center no-repeat;
}
.loadingGif.visible:after {
  display: block;
}
.loadingGif.spinner {
  border: 4px solid #c6c5c6;
  border-radius: 50%;
  border-top: 4px solid #003da5;
  width: 36px;
  height: 36px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: none;
}
.loadingGif.spinner:after {
  display: none;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.modale-form {
  position: absolute;
  top: 48px;
  right: 16px;
  bottom: 16px;
  left: 16px;
  width: calc(100vw - 32px);
  max-width: 752px;
  margin: auto;
}
@media screen and (min-width: 1140px) {
  .modale-form {
    position: relative;
    inset: 0;
    width: calc(100vw - 64px);
  }
}
.modale-form-close {
  position: absolute;
  top: -32px;
  right: 0;
  margin: 0;
  padding: 0;
  border: 0;
  background: rgba(0, 0, 0, 0);
}
.modale-form-overlay {
  position: fixed;
  max-width: 100vw;
  inset: 0;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 48px 16px;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
  z-index: 1001;
}
@media screen and (min-width: 768px) {
  .modale-form-overlay {
    padding: 48px 32px;
  }
}
.modale-form-open {
  overflow: hidden;
}
.modale-form-open .modale-form-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}